//imported
import * as Path from "routes/paths";
import {
  IMG_LOGO_1,
  IMG_LOGO_2,
  IMG_LOGO_3,
  DASHBOARD_PAYMENT,
  DASHBOARD_TRADE,
  DASHBOARD_USER,
} from "utils/constant";

const useModules = () => {
  let modules = [
    /******dashboard********** */
    {
      url: Path.dashboard,
      model_name: "dashboard",
      name: "Dashboard",
      icon: "mdi mdi-home menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: IMG_LOGO_1,
      isAdmin: true,
      is_modules: false,
      is_dashboard: false,
      is_permission: true,
      is_dropdown: false,
      count: 0,
      sub_modules: {},
    },
    /******Manage users********** */
    {
      url: Path.users,
      model_name: "userCount",
      name: "Manage Users",
      icon: "mdi mdi-account-multiple menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: DASHBOARD_USER,
      isAdmin: true,
      is_modules: true,
      is_permission: true,
      is_dashboard: true,
      is_dropdown: false,
      count: 0,
      sub_modules: {
        view: Path.user_view,
        view_form: Path.userview,
      },
    },
    /******Manage Payments********** */
    {
      url: Path.payment,
      model_name: "transactionCount",
      name: "Manage Payments",
      icon: "mdi mdi-wallet menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: DASHBOARD_PAYMENT,
      isAdmin: true,
      is_modules: true,
      is_permission: true,
      is_dashboard: true,
      is_dropdown: false,
      count: 0,
      sub_modules: {},
    },
    /******Manage Trades********** */
    {
      url: Path.trade,
      model_name: "tradeCount",
      name: "Manage Trades",
      icon: "mdi mdi-chart-bar menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: DASHBOARD_TRADE,
      isAdmin: true,
      is_modules: true,
      is_permission: true,
      is_dashboard: true,
      is_dropdown: false,
      count: 0,
      sub_modules: {
        view: Path.schedule_order,
        view_form: Path.schedule_orders,
      },
    },
    /******Revenue********** */
    {
      url: Path.revenue,
      model_name: "revenue",
      name: "Revenue",
      icon: "mdi mdi-cash-usd menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: "",
      isAdmin: true,
      is_modules: true,
      is_permission: true,
      is_dashboard: false,
      is_dropdown: false,
      count: 0,
      sub_modules: {},
    },
    /******Report********** */
    {
      url: Path.report,
      model_name: "report",
      name: "Report",
      icon: "mdi mdi-file-document menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: "",
      isAdmin: true,
      is_modules: true,
      is_permission: true,
      is_dashboard: false,
      is_dropdown: false,
      count: 0,
      sub_modules: {},
    },

    /******Affiliates & Referrals********* */
    {
      url: Path.affiliates,
      model_name: "affiliates",
      name: "Affiliates & Referrals",
      icon: "mdi mdi-yelp menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: "",
      isAdmin: true,
      is_modules: true,
      is_permission: true,
      is_dashboard: false,
      is_dropdown: false,
      count: 0,
      sub_modules: {},
    },
    /******Promotioons********** */
    // {
    //   url: Path.promotion,
    //   model_name: "promotions",
    //   name: "Promotions",
    //   icon: "mdi mdi-bullhorn menu-icon",
    //   dashboard_color: "bg-secondary",
    //   dashboard_img: "",
    //   isAdmin: true,
    //   is_modules: true,
    //   is_permission: true,
    //   is_dashboard: false,
    //   is_dropdown: false,
    //   count: 0,
    //   sub_modules: {
    //     add: Path.promotion_add,
    //     edit: Path.promotion_edit,
    //     edit_form: Path.promotionedit,
    //     view: Path.promotion_edit,
    //     view_form: Path.promotion_view,
    //   },
    // },

    /******BackOffice******** */
    {
      url: Path.backoffice,
      model_name: "backoffice",
      sub_model_name: "task",
      name: "Task",
      sub_name_1: "Backoffice",
      icon: "mdi mdi-format-list-bulleted menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: "",
      isAdmin: true,
      is_modules: true,
      is_permission: true,
      is_dashboard: false,
      is_dropdown: true,
      count: 0,
      sub_modules: {
        // add : Path.system_access_add,
        // edit : Path.system_access_edit,
        // edit_form : Path.system_accessedit,
        // view: Path.backoffice_view,
        // view_form: Path.backofficeview,
      },
      sub_modules_urls: [
        //   {
        //   url_1: Path.backoffice,
        //   url_2: Path.backoffice,
        // }
        {
          url: Path.backoffice,
          sub_model_name: "task",
          model_name: "backoffice",
          name: "Backoffice",
          icon: "mdi mdi-account-heart menu-icon",
          dashboard_color: "bg-secondary",
          is_dropdown: false,
          sub_modules: {
            // add: Path.testimony_add,
            // edit: Path.testimony_edit,
            // edit_form: Path.testimonyedit,
            // view: Path.testimony_edit,
            // view_form: Path.testimony_view,
          },
        },
      ],
    },

    /******Configuration********* */
    {
      url: Path.configuration,
      model_name: "configuration",
      name: "Automated Email",
      icon: "mdi mdi-settings menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: "",
      isAdmin: true,
      is_modules: true,
      is_permission: true,
      is_dashboard: false,
      is_dropdown: false,
      count: 0,
      sub_modules: {
        add: Path.configuration_add,
        edit: Path.configuration_edit,
        edit_form: Path.configurationedit,
      },
    },

    /******Content Pages********* */
    {
      url: Path.cms,
      model_name: "cms",
      name: "App Content",
      icon: "mdi mdi-table-large menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: "",
      isAdmin: true,
      is_modules: true,
      is_permission: true,
      is_dashboard: false,
      is_dropdown: false,
      count: 0,
      sub_modules: {
        add: Path.cmsform,
        edit: Path.cms_form,
      },
    },

    /******Landing Page********** */
    {
      url: "",
      model_name: "landingPage",
      sub_model_name: "landingPage",
      name: "Landing Page",
      icon: "mdi mdi-newspaper menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: "",
      isAdmin: true,
      is_modules: true,
      is_permission: true,
      is_dashboard: false,
      is_dropdown: true,
      count: 0,
      sub_modules: {},
      sub_modules_urls: [
        {
          model_name: "blogs",
          sub_model_name: "landingPage",
          url: Path.blogs,
          name: "Blogs",
          icon: "mdi mdi-newspaper menu-icon",
          dashboard_color: "bg-secondary",
          is_dropdown: false,
          sub_modules: {
            add: Path.blogs_add,
            edit: Path.blogs_edit,
            edit_form: Path.blogsedit,
            view: Path.blogsview,
            view_form: Path.blogs_view,
          },
        },
        {
          url: Path.jobs,
          model_name: "jobs",
          sub_model_name: "landingPage",
          name: "Jobs",
          icon: "mdi mdi-briefcase menu-icon",
          dashboard_color: "bg-secondary",
          is_dropdown: false,
          sub_modules: {
            add: Path.jobs_add,
            edit: Path.jobs_edit,
            edit_form: Path.jobsedit,
            view: Path.jobs_edit,
            view_form: Path.jobs_view,
          },
        },
        {
          url: Path.testimony,
          sub_model_name: "landingPage",
          model_name: "testimony",
          name: "Testimony",
          icon: "mdi mdi-account-heart menu-icon",
          dashboard_color: "bg-secondary",
          is_dropdown: false,
          sub_modules: {
            add: Path.testimony_add,
            edit: Path.testimony_edit,
            edit_form: Path.testimonyedit,
            view: Path.testimony_edit,
            view_form: Path.testimony_view,
          },
        },
        {
          url: Path.feature,
          model_name: "feature",
          sub_model_name: "landingPage",
          name: "Feature",
          icon: "mdi mdi-lightbulb menu-icon",
          dashboard_color: "bg-secondary",
          is_dropdown: false,
          sub_modules: {
            add: Path.feature_add,
            edit: Path.feature_edit,
            edit_form: Path.featureedit,
            view: Path.feature_edit,
            view_form: Path.feature_view,
          },
        },
        {
          url: Path.faq,
          model_name: "faq",
          sub_model_name: "landingPage",
          name: "FAQ",
          icon: "mdi mdi-format-list-bulleted menu-icon",
          dashboard_color: "bg-secondary",
          is_dropdown: false,
          sub_modules: {
            add: Path.faq_form,
            edit: Path.faqform,
          },
        },
        {
          url: Path.basicSetting,
          sub_model_name: "landingPage",
          model_name: "basicSetting",
          name: "Basic Setting",
          icon: "mdi mdi-briefcase menu-icon",
          dashboard_color: "bg-secondary",
          is_dropdown: false,
          sub_modules: {},
        },
      ],
    },
    /******JFX Page********** */
    {
      url: Path.jfxusers,
      model_name: "jfxPage",
      sub_model_name: "jfxPage",
      name: "JFX Page",
      icon: "mdi mdi-newspaper menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: "",
      isAdmin: false,
      is_modules: true,
      is_permission: true,
      is_dashboard: false,
      is_dropdown: false,
      count: 0,
      sub_modules: {},
    },

    /******Kliring Page********** */
    {
      url: Path.kliringusers,
      model_name: "kliringPage",
      sub_model_name: "kliringPage",
      name: "Kliring Page",
      icon: "mdi mdi-newspaper menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: "",
      isAdmin: false,
      is_modules: true,
      is_permission: true,
      is_dashboard: false,
      is_dropdown: false,
      count: 0,
      sub_modules: {},
    },

    /******System Access********* */
    {
      url: Path.system_access,
      model_name: "system_access",
      name: "System Access",
      icon: "mdi mdi-account-multiple-plus menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: "",
      isAdmin: true,
      is_modules: true,
      is_permission: true,
      is_dashboard: false,
      is_dropdown: false,
      count: 0,
      sub_modules: {
        add: Path.system_access_add,
        edit: Path.system_access_edit,
        edit_form: Path.system_accessedit,
        view: Path.system_access_view,
        view_form: Path.system_accessview,
      },
    },
    /******Bank Request********* */
    {
      url: Path.bank_request,
      model_name: "bank_request",
      name: "Bank Request",
      icon: "mdi mdi-format-list-bulleted menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: "",
      isAdmin: true,
      is_modules: true,
      is_permission: true,
      is_dashboard: false,
      is_dropdown: false,
      count: 0,
      sub_modules: {},
    },

    /******Manage logs********** */
    {
      url: Path.manage_logs,
      model_name: "manage_logs",
      name: "Manage Logs",
      icon: "mdi mdi-format-list-bulleted menu-icon",
      dashboard_color: "bg-secondary",
      dashboard_img: IMG_LOGO_2,
      isAdmin: true,
      is_modules: true,
      is_permission: true,
      is_dashboard: false,
      is_dropdown: false,
      count: 60,
      sub_modules: {
        view: Path.manage_logs_view,
        view_form: Path.manage_logsview,
      },
    },
    /******Manage Stocks********** */
    // {
    //   url: Path.manage_stock,
    //   model_name: "manage_stock",
    //   name: "Manage Stocks",
    //   icon: "mdi mdi-format-list-bulleted menu-icon",
    //   dashboard_color: "bg-secondary",
    //   dashboard_img: IMG_LOGO_2,
    //   is_modules: true,
    //   is_permission: true,
    //   is_dashboard: false,
    //   is_dropdown: false,
    //   count: 60,
    //   sub_modules: {

    //   },
    // },
  ];
  return modules;
};

export { useModules };
