import { useState } from "react";
import ShowLogsFullText from "./Modals/ShowLogsFullText";
import { DATE_FORMAT_TIME, FORMAT_TEXT_ } from "helpers/common";

const RenderListLogs = ({ logData }) => {
  const [model, setModel] = useState({ show: false, data: "" });
  return (
    <>
      <ShowLogsFullText model={model} setModel={setModel} />
      {logData?.length > 0 &&
        logData.map((list, i) => {
          return (
            <tr key={i} style={{ cursor: "pointer" }}>
              <td>
                {list?.updateData?.name
                  ? FORMAT_TEXT_(list?.updateData?.name)
                  : list?.customerName}
              </td>
              <td onClick={() => setModel({ show: true, data: list?.text })}>
                {FORMAT_TEXT_(list.text)}
              </td>
              <td>{list.type}</td>
              <td>{DATE_FORMAT_TIME(list.createdAt)}</td>
            </tr>
          );
        })}
    </>
  );
};

export default RenderListLogs;
