import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactPagination from "components/shared/ReactPagination";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

//imported
import * as Path from "routes/paths";
import { REVENUE, PAGE_LIMIT } from "helpers/viewContent";
import SearchFilter from "components/shared/SearchFilter";
import { getList_ } from "store/services/commonService";
import { DATE_FORMAT_TIME_, DATE_FORMAT_FOR_DB } from "helpers/common";
import Management from "./Management";

const Listing = ({ dispatch, itemsCountPerPage, totalItemsCount }) => {
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);

  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    search: "",
    status: "",
    type: "",
    order: 1,
    orderBy: "createdAt",
  });

  const [isLoading, setIsLoading] = useState(false);

  const [listing, setListing] = useState([]);
  const [fields, setfields] = useState({
    search_date: "",
  });
  console.log(fields?.search_date, "searchdate");

  let searchFilterDate = fields.search_date;
  console.log(searchFilterDate, "searchFilterDate");

  const [key, setKey] = useState(0);

  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        startDate: fields?.search_date ? searchFilterDate : "",
        url: "revenue",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, [filter, fields, tabIndex]);

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  const clearSerchFilter = () => {
    setfields({
      search_date: "",
    });
    setKey((prevKey) => prevKey + 1);
  };

  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  return (
    <>
      <Helmet title={REVENUE.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{REVENUE.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.revenue}>{REVENUE.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList>
                  <Tab>List</Tab>
                  <Tab>Revenue Management</Tab>
                </TabList>
                <TabPanel>
                  <h4 className="card-title">
                    <SearchFilter
                      searchValue={""}
                      handleSearchChange={""}
                      clearSerchFilter={clearSerchFilter}
                      options={""}
                      SearchText="Search by name"
                      isSearchDate={true}
                      setfields={setfields}
                      field={fields}
                      isExportButton={false}
                      isAddButton={false}
                      isSelectBox={false}
                      filter={filter}
                      setFilter={setFilter}
                      dropdownField="status"
                      key={key}
                      csvData={""}
                      isFutureHide={true}
                    />
                  </h4>
                  <div class="table-responsive">
                    <table className="table common_th">
                      <thead>
                        <tr>
                          <th onClick={() => handleSortingFunc("name")}>
                            {" "}
                            Client Name{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "name"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "name"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("createdAt")}>
                            Time
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>

                          <th> Type </th>
                          <th onClick={() => handleSortingFunc("amount")}>
                            Amount
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "amount"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "amount"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th> Account Id </th>
                          <th
                            onClick={() => handleSortingFunc("transactionId")}
                          >
                            Transaction ID
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionId"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionId"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th> Status</th>
                          <th> Description</th>
                        </tr>
                      </thead>
                      <tbody>
                        {listing?.length > 0 &&
                          listing.map((list, i) => {
                            return (
                              <tr key={i} style={{ cursor: "pointer" }}>
                                <td>
                                  {list?.userDetails?.first_name
                                    ? list?.userDetails?.first_name
                                    : "N/A"}
                                </td>
                                <td>{DATE_FORMAT_TIME_(list.createdAt)}</td>
                                <td>{list.type}</td>
                                <td>
                                  ${list.amount ? list.amount.toFixed(2) : "0"}
                                </td>
                                <td>
                                  {list?.userDetails?.accountId
                                    ? list?.userDetails?.accountId
                                    : "N/A"}
                                </td>
                                <td>
                                  {list.transactionId
                                    ? list.transactionId
                                    : "N/A"}
                                </td>
                                <td>
                                  <label className="badge badge-success payment_status">
                                    {list.status}
                                  </label>
                                </td>
                                <td>{list.text}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {listing.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={PAGE_LIMIT}
                        totalItemsCount={totalItemsCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {listing.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  <Management />
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
