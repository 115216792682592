import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

//imported
import ReactPagination from "components/shared/ReactPagination";
import * as Path from "routes/paths";
import { LOGS, PAGE_LIMIT } from "helpers/viewContent";
import { DATE_FORMAT_TIME, FORMAT_TEXT_ } from "helpers/common";

//services
import { getList_ } from "store/services/commonService";

const Listing = ({
  dispatch,
  itemsCountPerPage,
  totalItemsCount,
  userProfile,
  user,
}) => {
  let userPermission =
    userProfile && userProfile?.permissions?.length > 0
      ? JSON.parse(userProfile?.permissions)
      : {};
  let userData =
    user?.permissions && user?.permissions?.length > 0
      ? JSON.parse(user?.permissions)
      : userPermission;
  let userRole = userProfile ? userProfile?.role : user?.role;
  const [userSubPermissions, setuserSubPermissions] = useState(null);

  //pagination
  const [activePage, setActivePage] = useState(1);
  const [listing, setListing] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  //filter
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT,
    order: 1,
    orderBy: "createdAt",
  });

  //get data
  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: "log/getWithFilters",
        common: false,
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, [filter]);

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };
  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    let objPer = userData["manage_logs"];
    setuserSubPermissions(objPer);
  }, [userProfile]);

  return (
    <>
      <Helmet title={LOGS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{LOGS.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.manage_logs}>{LOGS.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">Listing</h4>
              <div class="table-responsive">
                <table className="table common_th">
                  <thead>
                    <tr>
                      <th onClick={() => handleSortingFunc("name")}>
                        {" "}
                        User{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 && filter?.orderBy === "name"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 && filter?.orderBy === "name"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      <th> Logs </th>
                      <th> Type </th>
                      <th onClick={() => handleSortingFunc("createdAt")}>
                        {" "}
                        Created At{" "}
                        <i
                          className={`cursor-pointer mdi mdi-arrow-${
                            filter?.order === -1 &&
                            filter?.orderBy === "createdAt"
                              ? "up"
                              : "down"
                          }`}
                          title={
                            filter?.order === -1 &&
                            filter?.orderBy === "createdAt"
                              ? "Desc"
                              : "Asc"
                          }
                        ></i>{" "}
                      </th>
                      {userRole === "SUBADMIN" &&
                      userSubPermissions !== undefined &&
                      userSubPermissions?.view ? (
                        <th> Action</th>
                      ) : (
                        userRole === "ADMIN" &&
                        userSubPermissions === undefined && <th> Action</th>
                      )}
                      {userRole === "SUBADMIN" &&
                      userSubPermissions !== undefined &&
                      userSubPermissions?.update ? (
                        <th>Module Action</th>
                      ) : (
                        userRole === "ADMIN" &&
                        userSubPermissions === undefined && <th> Action</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {listing?.length > 0 &&
                      listing.map((list, i) => {
                        let usersId = list?.updateData?.userId
                          ? list?.updateData?.userId
                          : list?.updateData?.customerId;
                        let LinkRedirection =
                          list?.type === "edit_user"
                            ? `${Path.userview}/${usersId}`
                            : list?.type === "comment_added"
                            ? `${Path.userview}/${usersId}`
                            : list?.type === "promotion_added"
                            ? `${Path.promotion}`
                            : list?.type === "task_added"
                            ? `${Path.backoffice}`
                            : list?.type === "edit_admin_profile"
                            ? `${Path.profile}`
                            : list?.type === "task_update"
                            ? `${Path.backoffice}`
                            : list?.type === "content_update"
                            ? `${Path.cms_form}/${list?.updateData?._id}`
                            : list?.type === "promotion_update"
                            ? `${Path.promotion_edit}/${list?.updateData?._id}`
                            : "";
                        return (
                          <tr key={i} style={{ cursor: "pointer" }}>
                            <td>
                              {list?.updateData?.name || list?.customerName
                                ? FORMAT_TEXT_(
                                    list?.updateData?.name || list?.customerName
                                  )
                                : "N/A"}
                            </td>
                            <td>{FORMAT_TEXT_(list.text)}</td>
                            <td>{list.type}</td>
                            <td>{DATE_FORMAT_TIME(list.createdAt)}</td>
                            <td>
                              {userRole === "SUBADMIN" &&
                              userSubPermissions !== undefined ? (
                                <>
                                  {userSubPermissions?.view && (
                                    <>
                                      <Link
                                        to={`${Path.manage_logsview}/${list?._id}`}
                                      >
                                        <label
                                          class="btn btn-sm"
                                          style={{ cursor: "pointer" }}
                                          title="View"
                                        >
                                          <i className="mdi mdi-eye"></i>
                                        </label>
                                      </Link>
                                    </>
                                  )}
                                </>
                              ) : (
                                userRole === "ADMIN" &&
                                userSubPermissions === undefined && (
                                  <>
                                    <Link
                                      to={`${Path.manage_logsview}/${list?._id}`}
                                    >
                                      <label
                                        class="btn btn-sm"
                                        style={{ cursor: "pointer" }}
                                        title="View"
                                      >
                                        <i className="mdi mdi-eye"></i>
                                      </label>
                                    </Link>
                                  </>
                                )
                              )}
                            </td>
                            <td>
                              {userRole === "SUBADMIN" &&
                              userSubPermissions !== undefined ? (
                                <>
                                  {userSubPermissions?.update && (
                                    <>
                                      <Link to={LinkRedirection}>
                                        <label
                                          class="btn btn-sm"
                                          style={{ cursor: "pointer" }}
                                          title="Module View"
                                        >
                                          <i className="mdi mdi-eye"></i>
                                        </label>
                                      </Link>
                                    </>
                                  )}
                                </>
                              ) : (
                                userRole === "ADMIN" &&
                                userSubPermissions === undefined && (
                                  <>
                                    <Link to={LinkRedirection}>
                                      <label
                                        class="btn btn-sm"
                                        style={{ cursor: "pointer" }}
                                        title="Module View"
                                      >
                                        <i className="mdi mdi-eye"></i>
                                      </label>
                                    </Link>
                                  </>
                                )
                              )}
                            </td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              {listing?.length > 0 && (
                <div className="pagination-box-review">
                  <ReactPagination
                    activePage={filter?.page}
                    itemsCountPerPage={PAGE_LIMIT}
                    totalItemsCount={totalItemsCount}
                    handlePageChange={handlePageChange}
                  />
                </div>
              )}

              {listing?.length == 0 && (
                <div className="row">
                  <div className="col-md-6 pt-5">
                    <span>No data found...</span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
