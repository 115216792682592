import React, { useState, useEffect, useCallback } from "react";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
//services
import { updateData_ } from "store/services/commonService";

export default ({
  showModal,
  handleModalClose,
  dispatch,
  revenueRecord,
  setShowModal,
  setRevenueRecord,
  fetchData,
}) => {
  const validationSchema = Yup.object().shape({
    tradeCommission: Yup.string().required("Required"),
    depositCommission: Yup.string().required("Required"),
    withdrawCommission: Yup.string().required("Required"),
    fee: Yup.string().required("Required"),
    affiliatedTradeCommission: Yup.string().required("Required"),
    sellTradeCommission: Yup.string().required("Required"),
  });
  const validateData = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      return { isValid: false, errors };
    }
  };
  const [showSubmitBtn, setshowSubmitBtn] = useState(false);

  const [validationErrors, setValidationErrors] = useState({});
  const [fields, setfields] = useState({
    tradeCommission: "",
    depositCommission: "",
    fee: "",
    affiliatedTradeCommission: "",
    withdrawCommission: "",
    sellTradeCommission: "",
  });
  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    []
  );

  //submit setting data
  const handleSubmitFunc = async (e) => {
    try {
      e.preventDefault();
      const validation = await validateData(fields);
      if (validation.isValid) {
        let obj = {
          url: "editBulkUserCommission",
          userIds: revenueRecord,
          //   userIds: JSON.stringify(revenueRecord),
          ...fields,
        };
        await dispatch(updateData_(obj));
        setshowSubmitBtn(false);
        setShowModal(false);
        setRevenueRecord([]);
        fetchData();
        setfields("");
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <Modal
        show={showModal}
        onHide={handleModalClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Update</Modal.Title>
        </Modal.Header>
        <hr />
        <Modal.Body>
          <div className="row">
            <div className="form-group col-md-12">
              <label for="exampleInputName1">Trades</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputName1"
                placeholder="0"
                name="tradeCommission"
                value={fields?.tradeCommission}
                min={0}
                onChange={handleChange("tradeCommission")}
              />
              {validationErrors.tradeCommission && (
                <div className="text-danger err">
                  {validationErrors.tradeCommission}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <label for="exampleInputName1">Deposit</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputName1"
                placeholder="0"
                name="depositCommission"
                min={0}
                value={fields?.depositCommission}
                onChange={handleChange("depositCommission")}
              />
              {validationErrors.depositCommission && (
                <div className="text-danger err">
                  {validationErrors.depositCommission}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <label for="exampleInputName1">Withdrawal</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputName1"
                placeholder="0"
                name="withdrawCommission"
                min={0}
                value={fields?.withdrawCommission}
                onChange={handleChange("withdrawCommission")}
              />
              {validationErrors.withdrawCommission && (
                <div className="text-danger err">
                  {validationErrors.withdrawCommission}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <label for="exampleInputName1">Fee</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputName1"
                placeholder="0"
                name="tradecommission"
                min={0}
                value={fields?.fee}
                onChange={handleChange("fee")}
              />
              {validationErrors.fee && (
                <div className="text-danger err">{validationErrors.fee}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <label for="exampleInputName1">Affiliate Fee</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputName1"
                placeholder="0"
                name="affiliatedTradeCommission"
                min={0}
                value={fields?.affiliatedTradeCommission}
                onChange={handleChange("affiliatedTradeCommission")}
              />
              {validationErrors.withdrawCommission && (
                <div className="text-danger err">
                  {validationErrors.affiliatedTradeCommission}
                </div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="form-group col-md-12">
              <label for="exampleInputName1">Sell Trade Commission</label>
              <input
                type="number"
                className="form-control"
                id="exampleInputName1"
                placeholder="0"
                name="sellTradeCommission"
                min={0}
                value={fields?.sellTradeCommission}
                onChange={handleChange("sellTradeCommission")}
              />
              {validationErrors.sellTradeCommission && (
                <div className="text-danger err">
                  {validationErrors.sellTradeCommission}
                </div>
              )}
            </div>
          </div>
          <hr />
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            onClick={(e) => handleSubmitFunc(e)}
            disabled={showSubmitBtn ? true : false}
            title="Update"
            className="btn btn-gradient-primary me-2"
          >
            {showSubmitBtn ? "Wait.." : "Update"}
          </button>
          <button
            type="button"
            className="btn btn-lg btn-outline-secondary"
            title="Cancel"
            onClick={handleModalClose}
          >
            Cancel{" "}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
