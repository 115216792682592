import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { connect } from "react-redux";
//imported
import PublicRoute from "./Public";
import PrivateRoute from "./Private";
import AuthRoute from "./Auth";
import routes from "./routes";
import * as Path from "./paths";
import { filter } from "lodash";

//...............................................
function Routes({ authentication, user }) {
  const permissions = JSON.parse(
    (user?.permissions && user?.permissions && user?.permissions[0]) || "{}"
  );
  const filterRoutes =
    user?.role === "ADMIN"
      ? routes
      : routes?.filter((item) => {
          if (item?.everyOneCanAccess || !item?.auth) {
            // everyOneCanAccess ===> like profile ,  change password page  ,  etc
            // !item?.auth ===> or which are not authenticated router
            return true;
          }

          if (
            permissions &&
            permissions[item?.isPermitted?.key] &&
            permissions[item?.isPermitted?.key][item?.isPermitted?.action]
          ) {
            // check if user have permissions for the specific route not not
            return true;
          }
          return false;
        });

  // console.log(filterRoutes, JSON.parse(user?.permissions[0] || "{}"), "roles");

  return (
    <Router>
      <Switch>
        <Route
          exact
          path="/"
          render={() => {
            return !authentication ? (
              <Redirect to={Path.login} />
            ) : (
              <Redirect to={Path.dashboard} />
            );
          }}
        />
        {filterRoutes.map((route) => {
          console.log(route, "Route");
          if (route.fallback) {
            return <AuthRoute key={route.path} {...route} />;
          }
          if (route.auth) {
            return <PrivateRoute key={route.path} {...route} />;
          }
          if (!route.auth) {
            return <PublicRoute key={route.path} {...route} />;
          }
        })}
      </Switch>
    </Router>
  );
}

const mapStateToPros = (state) => {
  return {
    authentication: state.Auth.isAuthenticated,
    user: state.Auth.user,
  };
};

function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Routes);
