import React, { useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

//imported
import * as Path from "routes/paths";
import { CONFIGURATION } from "helpers/viewContent";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
//services
import {
  addData_,
  updateData_,
  getDetail_,
} from "store/services/commonService";

const editorConfiguration = {
  toolbar: [
    "heading",
    "|",
    "bold",
    "italic",
    "link",
    "|",
    "bulletedList",
    "numberedList",
    "|",
    "blockQuote",
    "insertTable",
    "|",
    "mediaEmbed",
    "imageInsert",
    "fileBrowser",
    "exportPdf",
    "exportWord",
    "exportXml",
    "|",
    "undo",
    "redo",
  ],
  sourceEditing: {
    preview: true, // Enable the preview feature
    highlightActiveLine: true, // Enable highlighting the line the cursor is on
    highlightMatches: true, // Enable highlighting matching tag pairs
  },
};
const AddEdit = ({ dispatch, match }) => {
  const validationSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    emailTitleEn: Yup.string().required("Title is required"),
    emailTitle: Yup.string().required("Email Title is required"),
    body: Yup.string().required("Content is required"),
    body_en: Yup.string().required("Content is required"),
    status: Yup.string().required("Status is required"),
  });
  let editKey = match?.params?.id;
  const validateData = async (data) => {
    try {
      await validationSchema.validate(data, { abortEarly: false });
      return { isValid: true, errors: {} };
    } catch (error) {
      const errors = {};
      error.inner.forEach((err) => {
        errors[err.path] = err.message;
      });
      return { isValid: false, errors };
    }
  };
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);
  const [isShowEmail, setIsShowEmail] = useState(false);

  const [validationErrors, setValidationErrors] = useState({});
  const [fieldValue, setFieldValue] = useState(null);
  const [fields, setfields] = useState({
    title: "",
    email: "",
    emailTitleEn: "",
    emailTitle: "",
    body: "",
    body_en: "",
    isSendEn: false,
    status: 1,
  });

  const handleChange = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
      setValidationErrors((prevErrors) => ({ ...prevErrors, [name]: "" }));
    },
    []
  );

  //submit setting data
  const handleSubmit = async (evt) => {
    try {
      evt.preventDefault();
      const validation = await validateData(fields);
      if (validation.isValid) {
        let obj = {};
        setIsShow(true);
        if (editKey) {
          obj = {
            ...fields,
            url: "mailTemplate/edit",
            common: true,
            emailTemplateId: editKey,
          };
          await dispatch(updateData_(obj));
        } else {
          obj = {
            ...fields,
            common: true,
            url: "mailTemplate/add",
          };
          await dispatch(addData_(obj));
        }
        setIsShow(false);
        history.push(Path.configuration);
      } else {
        setValidationErrors(validation.errors);
      }
    } catch (err) {
      setIsShow(false);
      console.log(err);
    }
  };

  const handleSendEmail = async (evt) => {
    try {
      evt.preventDefault(); // Prevent default form submission behavior
      const validation = await validateData(fields);

      if (validation.isValid) {
        setIsShowEmail(true); // Show a loader or feedback UI
        const obj = {
          ...fields,
          url: "validateTemplateVariables",
          common: false,
          emailTemplateId: editKey,
        };
        await dispatch(updateData_(obj)); // Dispatch the update action
        setIsShowEmail(false); // Hide loader or feedback UI after successful operation
      } else {
        console.log("Validation failed:", validation.errors);
      }
    } catch (err) {
      setIsShowEmail(false); // Ensure feedback UI is hidden on error
      console.error("Error occurred while sending email:", err);
    }
  };

  async function getDataView() {
    try {
      let query = {
        url: "mailTemplate/getById",
        id: editKey,
        common: true,
      };
      let res = await dispatch(getDetail_(query));
      setfields(res?.data);
    } catch (err) {
      console.log(err);
    }
  }
  useEffect(() => {
    if (editKey) {
      getDataView();
    }
  }, []);

  return (
    <>
      <Helmet title={CONFIGURATION.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{CONFIGURATION.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.configuration}>
                {CONFIGURATION.CURRENT_MODULE}
              </Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {editKey ? "Edit" : "Add"}
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              <h4 className="card-title">
                {editKey ? "Edit" : "Add"} Configuration
              </h4>
              <form className="forms-sample" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label for="exampleInputName1">Title*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    placeholder="Title*"
                    name="title"
                    onChange={handleChange("title")}
                    disabled={true}
                    value={fields.title}
                  />
                  {validationErrors.title && (
                    <div className="text-danger err">
                      {validationErrors.title}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label for="exampleInputName1">Email*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    placeholder="Email*"
                    name="email"
                    onChange={handleChange("email")}
                    value={fields?.email}
                  />
                  {validationErrors?.email && (
                    <div className="text-danger err">
                      {validationErrors?.email}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label for="exampleInputName1">Email Title Indonesia*</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="exampleInputName1"
                    placeholder="Email Title*"
                    name="emailTitle"
                    onChange={handleChange("emailTitle")}
                    value={fields.emailTitle}
                  />
                  {validationErrors.emailTitle && (
                    <div className="text-danger err">
                      {validationErrors.emailTitle}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label for="exampleInputName1">Email Title English*</label>
                  <textarea
                    type="text"
                    className="form-control"
                    id="emailTitleEn"
                    placeholder="Email Title*"
                    name="emailTitleEn"
                    onChange={handleChange("emailTitleEn")}
                    value={fields.emailTitleEn}
                  />
                  {validationErrors.emailTitleEn && (
                    <div className="text-danger err">
                      {validationErrors.emailTitleEn}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label for="exampleTextarea1">Content In Indonesia*</label>
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={fields.body}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFieldValue(editor.getData());
                      setfields((prevState) => ({
                        ...prevState,
                        body: editor.getData(),
                      }));
                      setValidationErrors({ body: "" });
                    }}
                    className="ckeditor-control ckeditor-custom-height"
                  />
                  {validationErrors.body && (
                    <div className="text-danger err">
                      {validationErrors.body}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label for="exampleTextarea1">Content In English*</label>
                  <CKEditor
                    editor={ClassicEditor}
                    config={editorConfiguration}
                    data={fields.body_en}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      setFieldValue(editor.getData());
                      setfields((prevState) => ({
                        ...prevState,
                        body_en: editor.getData(),
                      }));
                      setValidationErrors({ body_en: "" });
                    }}
                    className="ckeditor-control ckeditor-custom-height"
                  />
                  {validationErrors.body_en && (
                    <div className="text-danger err">
                      {validationErrors.body_en}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <div className="row">
                    <label for="exampleInputPassword4">Send In*</label>
                    <div class="filter_drop_filter">
                      <select
                        class="form-select filler_search"
                        name="isSendEn"
                        value={fields?.isSendEn}
                        onChange={handleChange("isSendEn")}
                      >
                        <option value="" disabled={true}>
                          Select{" "}
                        </option>
                        <option value={true}>English</option>
                        <option value={false}>Indonesia</option>
                      </select>
                    </div>
                  </div>
                  {validationErrors.status && (
                    <div className="text-danger err">
                      {validationErrors.status}
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <div className="row">
                    <label for="exampleInputPassword4">Status*</label>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="">
                          <input
                            type="radio"
                            className=""
                            value={1}
                            name="status"
                            onChange={handleChange("status")}
                            checked={fields.status == 1 ? true : false}
                          />{" "}
                          Active
                        </label>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label className="">
                          <input
                            type="radio"
                            className=""
                            value={0}
                            name="status"
                            onChange={handleChange("status")}
                            checked={fields.status == 0 ? true : false}
                          />{" "}
                          Inactive{" "}
                        </label>
                      </div>
                    </div>
                  </div>
                  {validationErrors.status && (
                    <div className="text-danger err">
                      {validationErrors.status}
                    </div>
                  )}
                </div>

                <button
                  type="submit"
                  disabled={isShow ? true : false}
                  className="btn btn-gradient-primary me-2"
                >
                  {isShow ? "Loading.." : "Submit"}
                </button>
                <button
                  onClick={handleSendEmail}
                  type="submitcheck"
                  disabled={isShowEmail ? true : false}
                  className="btn btn-gradient-primary me-2"
                >
                  {isShowEmail ? "Loading.." : "Sending Email"}
                </button>
                <Link to={Path.configuration}>
                  <button className="btn btn-outline-secondary btn-lg">
                    Back
                  </button>
                </Link>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToPros = (state) => {
  return {};
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(AddEdit);
