import { DATE_FORMAT_TIME } from "helpers/common";
import React from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";

export default ({ model, setModel }) => {
  const handleClose = () => {
    setModel((p) => ({
      show: false,
      data: null,
    }));
  };

  return (
    <>
      <Modal
        show={model?.show}
        onHide={handleClose}
        backdrop="true"
        keyboard={false}
        className="axis_modal"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        onBackdropClick={handleClose}
      >
        <Modal.Body>
          <Link to="#" className="close_btn mb-2" onClick={handleClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
            >
              <path
                fill="#000"
                d="M6.4 19L5 17.6l5.6-5.6L5 6.4L6.4 5l5.6 5.6L17.6 5L19 6.4L13.4 12l5.6 5.6l-1.4 1.4l-5.6-5.6z"
              />
            </svg>
          </Link>

          <h2 className="text-center my-2">Affiliate Details</h2>
          <hr />

          <div className="axix_modal_content my-3">
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Account ID</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {model?.data?.userDetails?.affiliatedCode
                      ? model?.data?.userDetails?.affiliatedCode
                      : "N/A"}
                  </label>
                </li>
              </ul>
            </div>
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Customer</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {model?.data?.userDetails?.first_name
                      ? `${model?.data?.userDetails?.first_name} ${model?.data?.userDetails?.last_name}`
                      : model?.data?.userDetails?.name || "N/A"}
                  </label>
                </li>
              </ul>
            </div>

            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Date Become Member</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {DATE_FORMAT_TIME(model?.data?.createdAt)}
                  </label>
                </li>
              </ul>
            </div>

            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Sum of Trade Volume</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {model?.data?.userDetails?.tradeCount || 0}
                  </label>
                </li>
              </ul>
            </div>
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Total Commissions</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {model?.data?.totalCommission}
                  </label>
                </li>
              </ul>
            </div>
            <div className="line_up d-flex align-items-center gap-3 justify-content-center">
              <ul className="d-flex align-items-center gap-3 justify-content-center">
                <li>
                  <label className="col-form-label">
                    <strong>Last Trade Time</strong>
                  </label>
                </li>
                <li>
                  <label className="col-form-label">
                    {model?.data?.lastTradeDate
                      ? DATE_FORMAT_TIME(model?.data?.lastTradeDate)
                      : "N/A"}
                  </label>
                </li>
              </ul>
            </div>
            <hr />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
