import React, { useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { updateData_ } from "store/services/commonService";
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";

export default ({
  dispatch,
  selectedOption,
  setTabIndex,
  subadminId,
  fields,
  setfields,
  setbackButtonStatus,
}) => {
  const history = useHistory();
  const [isShow, setIsShow] = useState(false);

  selectedOption = selectedOption.length > 0 ? JSON.parse(selectedOption) : {};
  let dbPermissionArr = selectedOption ? selectedOption : {};
  const modules = [
    {
      module_name: "Dashboard",
      model_name: "dashboard",
      view: false,
    },
    // {
    //   module_name: "Promotions",
    //   model_name: "promotions",
    //   delete: false,
    //   view: false,
    //   update: false,
    //   create: false,
    // },
    {
      module_name: "Bank Request",
      model_name: "bank_request",
      // delete: false,
      view: false,
      update: false,
      // create: false,
    },
    {
      module_name: "System Access",
      model_name: "system_access",
      delete: false,
      view: false,
      update: false,
      create: false,
    },
    {
      module_name: "Manage Users",
      model_name: "userCount",
      balance_operation_pay: false,
      view: false,
      update: false,
      cip_info_view: false,
      cip_info_edit: false,
    },
    {
      module_name: "Manage Payments",
      model_name: "transactionCount",
      corporate_withdrawal: false,
      view: false,
    },
    {
      module_name: "Manage Trades",
      model_name: "tradeCount",
      view: false,
    },
    {
      module_name: "Revenue",
      model_name: "revenue",
      view: false,
    },
    {
      module_name: "Reports",
      model_name: "report",
      view: false,
    },
    {
      module_name: "Affiliates & Referrals",
      model_name: "affiliates",
      view: false,
    },
    // {
    //     'module_name': 'FAQ',
    //     'model_name': 'faq',
    //     'delete': false,
    //     'view': false,
    //     'update': false,
    //     'create': false,
    // },
    // {
    //     'module_name': 'Content Pages',
    //     'model_name': 'cms',
    //     'delete': false,
    //     'view': false,
    //     'update': false,
    //     'create': false,
    // },
    {
      module_name: "Backoffice (Task)",
      model_name: "backoffice",
      delete: false,
      view: false,
      update: false,
      create: false,
    },
    {
      module_name: "Manage Logs",
      model_name: "manage_logs",
      update: false,
      view: false,
    },
    {
      module_name: "App Content",
      model_name: "cms",
      delete: false,
      update: false,
      create: false,
      view: false,
    },
    {
      module_name: "Landing Page",
      model_name: "landingPage",
      delete: false,
      update: false,
      create: false,
      view: false,
    },
    {
      module_name: "Automated Email",
      model_name: "configuration",
      update: false,
      view: false,
    },
    {
      module_name: "JFX Page",
      model_name: "jfxPage",
      view: false,
    },
    {
      module_name: "Kliring Page",
      model_name: "kliringPage",
      view: false,
    },
  ];
  const [permissions, setPermissions] = useState(dbPermissionArr);
  const handleChange = (moduleName, action) => {
    setPermissions((prevPermissions) => {
      // Create a copy of the permissions object
      const updatedPermissions = { ...prevPermissions };

      // Toggle the action for the moduleName
      if (!updatedPermissions[moduleName]) {
        updatedPermissions[moduleName] = {}; // Initialize if not present
      }
      updatedPermissions[moduleName] = {
        ...updatedPermissions[moduleName],
        [action]: !updatedPermissions[moduleName]?.[action],
      };

      // If all actions are unchecked, remove the module from permissions
      const moduleActions = updatedPermissions[moduleName];
      if (
        moduleActions &&
        Object.values(moduleActions).every((value) => value === false)
      ) {
        delete updatedPermissions[moduleName];
      }

      return updatedPermissions; // Return the updated permissions object
    });
  };
  const [dashboardFields, setDashboardFields] = useState({
    isUserDashboard: fields?.isUserDashboard || false,
    isPaymentDashboard: fields?.isPaymentDashboard || false,
    isLastOpen: fields?.isLastOpen || false,
    isGraphPermission: fields?.isGraphPermission || false,
    isTradeDashboard: fields?.isTradeDashboard || false,
    isNewlyRegistered: fields?.isNewlyRegistered || false,
    isPendentClient: fields?.isPendentClient || false,
  });

  const handleChangeRole = useCallback(
    (name) => (evt) => {
      const value = evt.target.checked;
      setDashboardFields((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [setDashboardFields]
  );

  const handleSubmit = async () => {
    // Handle submission here, you can access the permissions object
    try {
      if (
        Object.keys(permissions).length !== 0 ||
        dashboardFields?.isUserDashboard ||
        dashboardFields?.isPaymentDashboard ||
        dashboardFields?.isLastOpen ||
        dashboardFields?.isGraphPermission ||
        dashboardFields?.isNewlyRegistered ||
        dashboardFields?.isTradeDashboard ||
        dashboardFields?.isPendentClient
      ) {
        let obj = {
          ...dashboardFields,
          userId: subadminId,
          url: "editAdmin",
          permissions: JSON.stringify(permissions),
        };
        setIsShow(true);
        let res = await dispatch(updateData_(obj));
        if (res.data?.status === "success") {
          setIsShow(false);
          history.push(Path.system_access);
          dispatch(actions.persist_store({ subadminId: null }));
        }
      } else {
        history.push(Path.system_access);
        setIsShow(false);
        dispatch(actions.persist_store({ subadminId: null }));
      }
    } catch (err) {
      setIsShow(false);
      console.log(err);
    }
  };

  const isActionEnabled = (moduleName, action) => {
    // Merge permissions with dbPermissionArr
    const final_obj = { ...permissions };

    // If the permissions are undefined, return false
    if (!final_obj[moduleName]) {
      return false;
    }
    // Check if action is enabled for the moduleName
    return final_obj[moduleName][action] || false;
  };
  const onBackButton = () => {
    setTabIndex(0);
    setbackButtonStatus(true);
  };

  return (
    <>
      <div className="row">
        {modules.map((module, index) => (
          <div className="form-group col-md-4" key={index}>
            <div className="row">
              <label>
                <h4>{module.module_name}</h4>
              </label>
              <div className="list-wrapper">
                <ul className="d-flex flex-column-reverse todo-list todo-list-custom">
                  {Object.keys(module).map((action, index) => {
                    if (
                      action !== "module_name" &&
                      action !== "model_name" &&
                      module[action] !== undefined
                    ) {
                      return (
                        <>
                          {module.model_name === "dashboard" && (
                            <>
                              <li>
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      name="isNewlyRegistered"
                                      className="checkbox"
                                      onChange={handleChangeRole(
                                        "isNewlyRegistered"
                                      )}
                                      checked={
                                        dashboardFields.isNewlyRegistered
                                      }
                                    />{" "}
                                    Newly Registered Clients Tab{" "}
                                    <i className="input-helper"></i>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      name="isLastOpen"
                                      className="checkbox"
                                      onChange={handleChangeRole("isLastOpen")}
                                      checked={dashboardFields?.isLastOpen}
                                    />{" "}
                                    Last Open Positions Tab{" "}
                                    <i className="input-helper"></i>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      name="isPendentClient"
                                      className="checkbox"
                                      onChange={handleChangeRole(
                                        "isPendentClient"
                                      )}
                                      checked={dashboardFields?.isPendentClient}
                                    />{" "}
                                    Pendent Clients Tab{" "}
                                    <i className="input-helper"></i>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      name="isGraphPermission"
                                      className="checkbox"
                                      onChange={handleChangeRole(
                                        "isGraphPermission"
                                      )}
                                      checked={
                                        dashboardFields?.isGraphPermission
                                      }
                                    />{" "}
                                    Graph Tab <i className="input-helper"></i>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      name="isTradeDashboard"
                                      className="checkbox"
                                      onChange={handleChangeRole(
                                        "isTradeDashboard"
                                      )}
                                      checked={
                                        dashboardFields?.isTradeDashboard
                                      }
                                    />{" "}
                                    Manage Trades Tab{" "}
                                    <i className="input-helper"></i>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      name="isPaymentDashboard"
                                      className="checkbox"
                                      onChange={handleChangeRole(
                                        "isPaymentDashboard"
                                      )}
                                      checked={
                                        dashboardFields?.isPaymentDashboard
                                      }
                                    />{" "}
                                    Manage Payments Tab{" "}
                                    <i className="input-helper"></i>
                                  </label>
                                </div>
                              </li>
                              <li>
                                <div className="form-check">
                                  <label className="form-check-label">
                                    <input
                                      type="checkbox"
                                      name="isUserDashboard"
                                      className="checkbox"
                                      onChange={handleChangeRole(
                                        "isUserDashboard"
                                      )}
                                      checked={dashboardFields?.isUserDashboard}
                                    />{" "}
                                    Manage Users Tab{" "}
                                    <i className="input-helper"></i>
                                  </label>
                                </div>
                              </li>
                            </>
                          )}
                          <li key={index}>
                            <div className="form-check">
                              <label className="form-check-label">
                                <input
                                  name={`${module.model_name}_${action}`}
                                  onChange={() =>
                                    handleChange(module.model_name, action)
                                  }
                                  className="checkbox"
                                  type="checkbox"
                                  checked={isActionEnabled(
                                    module.model_name,
                                    action
                                  )}
                                />{" "}
                                {(
                                  action.charAt(0).toUpperCase() +
                                  action.slice(1)
                                ).replace(/_/g, " ")}{" "}
                                <i className="input-helper"></i>
                              </label>
                            </div>
                          </li>
                        </>
                      );
                    } else {
                      return null;
                    }
                  })}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
      <button
        type="submit"
        disabled={isShow ? true : false}
        onClick={handleSubmit}
        className="btn btn-gradient-primary me-2"
      >
        {isShow ? "Wait.." : "Submit"}
      </button>
      <button
        type="submit"
        className="btn btn-outline-secondary btn-lg"
        onClick={() => onBackButton()}
      >
        Back
      </button>
    </>
  );
};
