import React, { useState, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { debounce } from "lodash";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
//imported
import ReactPagination from "components/shared/ReactPagination";
import * as Path from "routes/paths";
import { TRADES, PAGE_LIMIT_TRADE } from "helpers/viewContent";
import TradeModal from "./TradeModal";
import SearchFilter from "components/shared/SearchFilter";
import {
  DATE_FORMAT_TIME_,
  closeTradesDatacsv,
  tradesDatacsv,
  tradesSchduleDatacsv,
} from "helpers/common";
//services
import { getList_ } from "store/services/commonService";
import Exposure from "./Exposure";
import ScheduleOrder from "./ScheduleOrder";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/react";

const Listing = ({
  dispatch,
  itemsCountPerPage,
  totalItemsCount,
  loader: commonLoader,
  orderIndex,
}) => {
  console.log(orderIndex, "orderIndex");
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [itemsCount, setItemsCount] = useState(0);
  const [itemsCountS, setItemsCountS] = useState(0);
  const [itemsCountC, setItemsCountC] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isUpdateApi, setisUpdateApi] = useState(false);
  const [csvDataP, setCsvDataP] = useState([]);
  const [tabIndex, setTabIndex] = useState(orderIndex ? orderIndex : 0);
  const [listingSchedule, setListingSchedule] = useState([]);
  const [closeTrades, setCloseTrades] = useState([]);
  const [closeTradeCSV, setCloseTradeCSV] = useState([]);
  const [key, setKey] = useState(0);
  const [field, setfields] = useState({
    search_date: new Date(),
  });
  const [field2, setfields2] = useState({
    startDate: "",
    endDate: "",
  });

  //filter
  const [filter, setFilter] = useState({
    page: activePage,
    limit: PAGE_LIMIT_TRADE,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });
  const [listing, setListing] = useState([]);
  //filter
  let listToDisplay = listing;
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = listing.filter((list) => {
      const itemName = list?.name?.toLowerCase();
      const itemClientName = list?.userDetails?.first_name.toLowerCase();
      const itemaccountId =
        list?.userDetails?.accountData?.account_number.toLowerCase();
      const stockCode = list?.symbol;
      return (
        itemName?.includes(searchTerm) ||
        itemClientName?.includes(searchTerm) ||
        itemaccountId?.includes(searchTerm) ||
        stockCode?.includes(searchTerm.toUpperCase())
      );
    });
  }

  const [currentValue, setCurrentValue] = useState(null);
  //show modal
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
    setCurrentValue(null);
  };
  const handleShow = (list) => {
    setShow(true);
    setCurrentValue(list);
    setCurrentValue(list);
  };

  const fetchData = async () => {
    try {
      let query = {
        ...filter,
        url: "getUserTradesWithFilter",
      };
      setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListing(res?.data?.data);
      setItemsCount(res?.data?.totalcount);
      // let arrP = await tradesDatacsv(res?.data?.data);
      // setCsvDataP(arrP);
      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
      console.log(err);
    }
  };

  const csvDataDownload_ = async () => {
    try {
      // Initialize query object
      let query = {};

      // Build query based on tabIndex
      if (tabIndex === 1) {
        query = {
          ...filter,
          startDate: field2.startDate,
          endDate: field2.endDate,
          url: "get/closeCancelWitoutpaginationTrade",
        };
      } else if (filter.status === "schedule") {
        query = {
          ...filter,
          url: "getScheduledOrders",
        };
      } else {
        query = {
          ...filter,
          url: "getUsersTradesWithOutPaginationFilter",
        };
      }

      // Set loading state
      // setIsLoading(true);
      console.log("Fetching data with query:", query);

      // Dispatch the query
      const res = await dispatch(getList_(query));
      console.log("Data fetched:", res);

      // Process the response based on tabIndex
      if (res) {
        let arrP;
        if (tabIndex === 1) {
          arrP = await closeTradesDatacsv(res?.data?.data);
          setCloseTradeCSV(arrP);
          console.log("Close Trade CSV Data:", arrP);
        } else if (filter.status === "schedule") {
          arrP = await tradesSchduleDatacsv(res?.data?.data);
          setCsvDataP(arrP);
        } else {
          arrP = await tradesDatacsv(res?.data?.data);
          setCsvDataP(arrP);
          console.log("Trades CSV Data:", arrP);
        }
      }
      // Reset loading state
      // setIsLoading(false);
      console.log("Loading state set to false");
    } catch (err) {
      // Reset loading state and log error
      // setIsLoading(false);
      console.log(err);
    }
  };

  const fetchScheduleData = async () => {
    try {
      let query = {
        ...filter,
        url: "getScheduledOrders",
      };
      // setIsLoading(true);
      let res = await dispatch(getList_(query));
      setListingSchedule(res?.data?.data);
      setItemsCountS(res?.data?.totalcount);

      // let arrP = await tradesSchduleDatacsv(res?.data?.data);
      // setCsvDataP(arrP);
      // setIsLoading(false);
    } catch (err) {
      // setIsLoading(false);
      console.log(err);
    }
  };

  const closeTradeData = async () => {
    try {
      let query = {
        ...filter,
        startDate: field2.startDate,
        endDate: field2.endDate,
        url: "get/closeCancelTrade",
      };
      // setIsLoading(true);
      let res = await dispatch(getList_(query));
      setCloseTrades(res?.data?.data);
      setItemsCountC(res?.data?.totalcount);

      // let arrP = await closeTradesDatacsv(res?.data?.data);
      // setCloseTradeCSV(arrP);
      // setIsLoading(false);
    } catch (err) {
      // setIsLoading(false);
      console.log(err);
    }
  };

  useEffect(() => {
    if (tabIndex === 1) {
      closeTradeData();
    } else if (tabIndex === 0) {
      fetchData();
      fetchScheduleData();
    }
    csvDataDownload_();
  }, [filter, isUpdateApi, tabIndex, field2]);

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue,
    });
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);

  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      search: "",
      status: "",
    });
    setfields2({
      startDate: "",
      endDate: "",
    });
    setKey((prevKey) => prevKey + 1);
  };
  //pagination
  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  const selectSearchOption = [
    {
      id: "1",
      value: "opened",
      name: "Open Trades",
    },
    {
      id: "2",
      value: "pending",
      name: "Pending Trades",
    },
    // {
    //   id: "3",
    //   value: "schedule",
    //   name: "Schedule Trades",
    // },
  ];

  const handleChangePicker = async (selectedDate, name) => {
    // Perform actions based on the selected date and field name
    let dateFormat = selectedDate ? selectedDate.format("DD/MM/YYYY") : "";
    setfields2((prev) => ({
      ...prev,
      [name]: dateFormat,
    }));
    // Rest of your code...
  };

  //render html
  const renderList_ = () => {
    return (
      listToDisplay?.length > 0 &&
      listToDisplay.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }} title="View">
          <td>
            {list?.userDetails?.first_name
              ? list?.userDetails?.first_name
              : "N/A"}
          </td>
          <td
            onClick={() =>
              list?.userDetails?.accountData?.account_number
                ? handleShow(list)
                : console.log("not exists")
            }
            title="view"
            className="cursor-pointer"
          >
            <b>
              {list?.userDetails?.accountData?.account_number
                ? list?.userDetails?.accountData?.account_number
                : "N/A"}
            </b>
          </td>
          <td className="green_status">
            $
            {list?.userDetails?.walletCredit
              ? (list.userDetails?.walletCredit).toFixed(2)
              : "0"}
          </td>
          <td>${list?.commission ? (list?.commission).toFixed(2) : "0"}</td>
          <td>
            $
            {list?.userDetails?.equity
              ? (list.userDetails?.equity).toFixed(2)
              : "0"}
          </td>
          <td>{list.shortName ? list?.shortName : list.name}</td>
          <td
            onClick={() => renderTradesGraph(list?.symbol)}
            className="cursor-pointer"
            title="View"
          >
            <b>{list?.symbol}</b>
          </td>

          <td>
            ${list?.avg_entry_price ? (list?.avg_entry_price).toFixed(2) : "0"}
          </td>
          {/* <td>${list?.boughtPrice ? (list?.boughtPrice).toFixed(2) : "0"}</td> */}
          <td>
            ${list?.current_price ? (list?.current_price).toFixed(2) : "0"}
          </td>
          <td>${list?.amount ? (list?.amount).toFixed(2) : "0"}</td>
          <td>{list.qty ? list.qty : "0"}</td>
          <td>
            <label
              className={
                list?.unrealized_pl && list.unrealized_pl < 0
                  ? "badge badge-danger"
                  : "badge badge-success"
              }
            >
              {list?.unrealized_pl ? list?.unrealized_pl?.toFixed(2) : "0"}
            </label>
          </td>
          <td>{getStatus(list.status)}</td>
        </tr>
      ))
    );
  };
  //render html schedule
  const renderListS_ = () => {
    return (
      listingSchedule?.length > 0 &&
      listingSchedule.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }} title="View">
          <td>
            {list?.userDetails?.first_name
              ? list?.userDetails?.first_name
              : "N/A"}
          </td>
          <td
            onClick={() =>
              list?.userDetails?.accountId
                ? handleShow(list)
                : console.log("not exists")
            }
            title="view"
            className="cursor-pointer"
          >
            <b>
              {list?.userDetails?.accountData?.account_number
                ? list?.userDetails?.accountData?.account_number
                : "N/A"}
            </b>
          </td>
          <td className="green_status">
            ${list?.commission ? (list?.commission).toFixed(2) : "0"}
          </td>
          <td>${list?.orderPrice ? (list?.orderPrice).toFixed(2) : "0"}</td>
          <td>{list.stockName ? list?.stockName : "N/A"}</td>
          <td>${list?.stockPrice ? (list?.stockPrice).toFixed(2) : "0"}</td>
          <td
            onClick={() => renderTradesGraph(list?.symbol)}
            className="cursor-pointer"
            title="View"
          >
            <b>{list?.symbol}</b>
          </td>
          <td>{list?.scheduledType ? list?.scheduledType : "0"}</td>
          <td>
            <label className="badge badge-success payment_status">
              {list.status}
            </label>
          </td>
          <td>{list.qty ? list?.qty : "0"}</td>
        </tr>
      ))
    );
  };

  const getStatus = (status, list) => {
    if (status === "accepted") {
      return <label className={"badge badge-success"}>{status}</label>;
    } else if (status === "pending") {
      return <label className={"badge badge-pending"}>{status}</label>;
    } else if (status === "closed") {
      return <label className={"badge badge-close"}>{status}</label>;
    } else if (status === "accepted" && list?.type === "sell") {
      return <label className={"badge badge-close"}>closed</label>;
    } else {
      return <label className={"badge badge-danger"}>{status}</label>;
    }
  };

  const renderCloseList_ = () => {
    return (
      closeTrades?.length > 0 &&
      closeTrades?.map((list, i) => (
        <tr key={i} style={{ cursor: "pointer" }} title="View">
          <td>
            {list?.userDetails?.first_name
              ? list?.userDetails?.first_name
              : "N/A"}
          </td>
          <td
            onClick={() =>
              list?.userDetails?.accountData?.account_number
                ? handleShow(list)
                : console.log("not exists")
            }
            title="view"
            className="cursor-pointer"
          >
            <b>
              {list?.userDetails?.accountData?.account_number
                ? list?.userDetails?.accountData?.account_number
                : "N/A"}
            </b>
          </td>
          <td
            onClick={() =>
              list?.orderNumber
                ? handleShow(list)
                : console.log("not exists")
            }
            title="view"
            className="cursor-pointer"
          >
            <b>{list?.orderNumber ? list?.orderNumber : "N/A"}</b>
          </td>
          <td
            onClick={() =>
              list?.createdAt ? handleShow(list) : console.log("not exists")
            }
            title="view"
            className="cursor-pointer"
          >
            <b>
              {list?.createdAt ? DATE_FORMAT_TIME_(list?.createdAt) : "N/A"}
            </b>
          </td>
          <td>{list.symbol ? list?.symbol : "N/A"}</td>
          <td>
            ${list?.requestAmount ? list?.requestAmount?.toFixed(2) : "0"}
          </td>
          <td className="green_status">
            ${list?.commission ? list?.commission?.toFixed(2) : "0"}
          </td>
          <td>
            ${list?.average_price ? list?.average_price?.toFixed(2) : "0"}
          </td>
          {/* <td>${list?.requestPrice ? list?.requestPrice?.toFixed(2) : "0"}</td> */}
          <td>{list?.qty ? list?.qty : "0"}</td>
          <td>{getStatus(list.status, list)}</td>
        </tr>
      ))
    );
  };

  const renderTradesGraph = (symbol) => {
    const url = `https://www.tradingview.com/symbols/${symbol}/`;
    window.open(url, "_blank");
  };

  return (
    <>
      {/*  only show loader if commonLoader is false and this component loader is true  */}
      {!commonLoader && isLoading && (
        <div
          style={{
            position: "fixed",
            inset: 0,
            zIndex: 99999,
          }}
        >
          <div className="overlay_screen">
            <div className="main-loader">
              <ClipLoader
                color={"#860000"}
                loading={isLoading}
                css={css`
                  display: block;
                  margin: 0 auto;
                `}
                size={80}
                speedMultiplier={1}
              />
            </div>
          </div>
        </div>
      )}
      <Helmet title={TRADES.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{TRADES.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.trade}>{TRADES.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => setTabIndex(index)}>
        <TabList>
          <Tab>Open Trades</Tab>
          <Tab>Close Trades</Tab>
          <Tab>Overall Exposure</Tab>
          <Tab>Scheduled Orders</Tab>
        </TabList>{" "}
        <TabPanel>
          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card graph_card">
                <div className="card-body">
                  <h4 className="card-title">
                    <SearchFilter
                      searchValue={searchValue}
                      handleSearchChange={handleSearchChange}
                      clearSerchFilter={clearSerchFilter}
                      options={selectSearchOption}
                      SearchText="Search by client/stock name"
                      isSearchDate={false}
                      field={field}
                      setfields={setfields}
                      isExportButton={true}
                      isAddButton={false}
                      isSelectBox={true}
                      isInput={true}
                      filter={filter}
                      setFilter={setFilter}
                      isSelectOtherBox={false}
                      dropdownField="status"
                      csvData={csvDataP}
                      csvDataDownload_={csvDataDownload_}
                    />
                  </h4>
                  <div class="table-responsive">
                    <table className="table common_th">
                      {filter.status === "schedule" && (
                        <thead>
                          <tr>
                            <th onClick={() => handleSortingFunc("first_name")}>
                              {" "}
                              Client's Name{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "first_name"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "first_name"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("accountId")}>
                              {" "}
                              Account Id{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "accountId"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "accountId"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("commission")}>
                              {" "}
                              Commission{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "commission"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "commission"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("orderPrice")}>
                              {" "}
                              Order Price{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "orderPrice"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "orderPrice"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("name")}>
                              {" "}
                              Stock Name{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "name"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "name"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("stockPrice")}>
                              {" "}
                              Stock Price
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "stockPrice"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "stockPrice"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("symbol")}>
                              {" "}
                              Symbol
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "symbol"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "symbol"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th
                              onClick={() => handleSortingFunc("scheduledType")}
                            >
                              {" "}
                              Scheduled Type{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "scheduledType"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "scheduledType"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th> Status</th>
                            <th onClick={() => handleSortingFunc("qty")}>
                              {" "}
                              Qty
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "qty"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "qty"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                          </tr>
                        </thead>
                      )}
                      {filter.status !== "schedule" && (
                        <thead>
                          <tr>
                            <th onClick={() => handleSortingFunc("user_name")}>
                              {" "}
                              Client's Name{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "user_name"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "user_name"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("accountId")}>
                              {" "}
                              Account Id{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "accountId"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "accountId"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th
                              onClick={() =>
                                handleSortingFunc("userDetails.walletCredit")
                              }
                            >
                              {" "}
                              Balance{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "userDetails.walletCredit"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "userDetails.walletCredit"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("commission")}>
                              {" "}
                              Commission{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "commission"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "commission"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th
                              onClick={() =>
                                handleSortingFunc("userDetails.equity")
                              }
                            >
                              {" "}
                              Equity{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "userDetails.equity"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "userDetails.equity"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("name")}>
                              {" "}
                              Stock Name{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "name"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "name"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("symbol")}>
                              {" "}
                              Symbol
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "symbol"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "symbol"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            {/* <th onClick={() => handleSortingFunc("price")}>
                              {" "}
                              Price
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "price"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "price"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th> */}

                            <th
                              onClick={() =>
                                handleSortingFunc("avg_entry_price")
                              }
                            >
                              {" "}
                              Average Price{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "avg_entry_price"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "avg_entry_price"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            {/* <th
                              onClick={() => handleSortingFunc("boughtPrice")}
                            >
                              {" "}
                              Bought Price{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "boughtPrice"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "boughtPrice"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th> */}
                            <th
                              onClick={() => handleSortingFunc("current_price")}
                            >
                              {" "}
                              Current Price{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "current_price"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "current_price"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("amount")}>
                              {" "}
                              Amount{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "amount"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "amount"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("qty")}>
                              {" "}
                              Qty
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "qty"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "qty"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th
                              onClick={() => handleSortingFunc("unrealized_pl")}
                            >
                              {" "}
                              Open PN
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "unrealized_pl"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "unrealized_pl"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>{" "}
                            </th>
                            <th onClick={() => handleSortingFunc("status")}>
                              Status{" "}
                              <i
                                className={`cursor-pointer mdi mdi-arrow-${
                                  filter?.order === -1 &&
                                  filter?.orderBy === "status"
                                    ? "up"
                                    : "down"
                                }`}
                                title={
                                  filter?.order === -1 &&
                                  filter?.orderBy === "status"
                                    ? "Desc"
                                    : "Asc"
                                }
                              ></i>
                            </th>
                          </tr>
                        </thead>
                      )}
                      {filter.status === "schedule" && (
                        <tbody>{renderListS_()}</tbody>
                      )}
                      {filter.status !== "schedule" && (
                        <tbody>{renderList_()}</tbody>
                      )}
                    </table>
                  </div>{" "}
                  {filter.status !== "schedule" && (
                    <>
                      {listing.length > 0 && (
                        <div className="pagination-box-review">
                          <ReactPagination
                            activePage={filter?.page}
                            itemsCountPerPage={PAGE_LIMIT_TRADE}
                            totalItemsCount={itemsCount}
                            handlePageChange={handlePageChange}
                          />
                        </div>
                      )}
                      {listing.length == 0 && (
                        <div className="row">
                          <div className="col-md-6 pt-5">
                            <span>No data found...</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {filter.status === "schedule" && (
                    <>
                      {listingSchedule?.length > 0 && (
                        <div className="pagination-box-review">
                          <ReactPagination
                            activePage={filter?.page}
                            itemsCountPerPage={PAGE_LIMIT_TRADE}
                            totalItemsCount={itemsCountS}
                            handlePageChange={handlePageChange}
                          />
                        </div>
                      )}
                      {listingSchedule?.length == 0 && (
                        <div className="row">
                          <div className="col-md-6 pt-5">
                            <span>No data found...</span>
                          </div>
                        </div>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          {/* <Exposure /> */}

          <div className="row">
            <div className="col-lg-12 grid-margin stretch-card">
              <div className="card graph_card">
                <div className="card-body">
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="card-title">
                      <p className="invisible">hidden text</p>
                      <SearchFilter
                        searchValue={searchValue}
                        handleSearchChange={handleSearchChange}
                        clearSerchFilter={clearSerchFilter}
                        SearchText="Search by client/stock name"
                        isSearchDate={false}
                        isExportButton={true}
                        isAddButton={false}
                        isInput={true}
                        filter={filter}
                        setFilter={setFilter}
                        isSelectOtherBox={false}
                        dropdownField="status"
                        csvData={closeTradeCSV}
                        setActivePage={setActivePage}
                        isStartEndDateFilter={true}
                        setfields={setfields}
                        field={field}
                        key={key}
                        setKey={setKey}
                        csvDataDownload_={csvDataDownload_}
                      />
                    </h4>
                    <div class="fillte_main flex-wrap d-flex gap-3 align-items-end">
                      <div class="filter_drop_filter">
                        <h6>From Date</h6>
                        <Datetime
                          key={key}
                          value={field2.startDate || ""}
                          inputProps={{
                            placeholder: "DD/MM/YYYY",
                            readOnly: true,
                            className: "form-control class-time-date-time",
                          }}
                          name="startDate"
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          isValidDate={(current) => {
                            return current.isSameOrBefore(moment(), "day");
                          }}
                          onChange={(selectedDate) =>
                            handleChangePicker(selectedDate, "startDate")
                          }
                          closeOnSelect={true}
                        />
                      </div>
                      <div class="filter_drop_filter">
                        <h6>To Date</h6>
                        <Datetime
                          key={key}
                          value={field2.endDate || ""}
                          inputProps={{
                            placeholder: "DD/MM/YYYY",
                            readOnly: true,
                            className: "form-control class-time-date-time",
                          }}
                          name="endDate"
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          isValidDate={(current) => {
                            // Parse the current date string in DD/MM/YYYY format to a moment object
                            const currentDate = moment(
                              current,
                              "DD/MM/YYYY",
                              true
                            );
                            // Parse the start date string in DD/MM/YYYY format to a moment object
                            const startDate = moment(
                              field2?.startDate,
                              "DD/MM/YYYY",
                              true
                            );
                            // Check if the current date is the same as or after the start date
                            return (
                              currentDate.isValid() &&
                              startDate.isValid() &&
                              currentDate.isSameOrAfter(startDate, "day")
                            );
                          }}
                          onChange={(selectedDate) =>
                            handleChangePicker(selectedDate, "endDate")
                          }
                          closeOnSelect={true}
                        />
                      </div>
                      <div class="filter_drop_filter">
                        <button
                          type="button"
                          class="btn btn-gradient-primary btn-lg px-3"
                          onClick={clearSerchFilter}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>

                  <div class="table-responsive">
                    <table className="table common_th">
                      <thead>
                        <tr>
                          <th onClick={() => handleSortingFunc("user_name")}>
                            Client's Name{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "user_name"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "user_name"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("accountId")}>
                            Account Id{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "accountId"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "accountId"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("_id")}>
                            Trade Id{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "_id"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "_id"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("createdAt")}>
                            {" "}
                            Order Date{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "createdAt"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("symbol")}>
                            {" "}
                            Symbol{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "symbol"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "symbol"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("stockPrice")}>
                            {" "}
                            Stock Amount
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "stockPrice"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "stockPrice"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("commission")}>
                            Commission{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "commission"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "commission"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("orderPrice")}>
                            Order Price{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("qty")}>
                            {" "}
                            Qty
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "qty"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "qty"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("status")}>
                            Status{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "status"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "status"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                        </tr>
                      </thead>

                      <tbody>{renderCloseList_()}</tbody>
                    </table>
                  </div>

                  {closeTrades?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={PAGE_LIMIT_TRADE}
                        totalItemsCount={itemsCountC}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {closeTrades?.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </TabPanel>
        <TabPanel>
          <Exposure />
        </TabPanel>
        <TabPanel>
          <ScheduleOrder
            dispatch={dispatch}
            tabIndex={tabIndex}
            setIsLoading={setIsLoading}
            handleShow={handleShow}
          />
        </TabPanel>
      </Tabs>
      <TradeModal
        show={show}
        handleClose={handleClose}
        setShow={setShow}
        currentValue={currentValue}
        setCurrentValue={setCurrentValue}
        tabIndex={tabIndex}
        dispatch={dispatch}
        setisUpdateApi={setisUpdateApi}
        filterType={filter?.status}
      />
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    // loader: state.Common.loader,
    loader: state.persistStore.loader,

    orderIndex: state.persistStore.orderIndex,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
