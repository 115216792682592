import React, { useState, useEffect, useMemo } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import Datetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import { debounce } from "lodash";

//imported
import ReactPagination from "components/shared/ReactPagination";
import { AFFILIATE, itemsPerPage } from "helpers/viewContent";
import { DATE_FORMAT_TIME, paidTransacrionCSV } from "helpers/common";
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
import { getList_, addData_ } from "store/services/commonService";
import SearchFilter from "components/shared/SearchFilter";

const Listing = ({ dispatch, itemsCountPerPage, totalItemsCount, loader }) => {
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);
  const [closeTrades, setCloseTrades] = useState([]);
  const [closeTradeCSV, setCloseTradeCSV] = useState([]);
  const [key, setKey] = useState(0);
  const [filter, setFilter] = useState({
    page: activePage,
    limit: itemsPerPage,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });
  const [affiliateListing, setaffiliateListing] = useState([]);
  const [affiliateCount, setaffiliateCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");
  const [transactionListing, settransactionListing] = useState([]);
  const [transactionCount, settransactionCount] = useState(0);

  const [existentListing, setExistentListing] = useState([]);
  const [existentCount, setExistentCount] = useState(0);

  const [paidListing, setpaidListing] = useState([]);
  const [paidCount, setpaidCount] = useState(0);

  //filter
  let listToDisplay = paidListing;
  if (filter?.search !== "") {
    const searchTerm = filter?.search.toLowerCase();
    listToDisplay = paidListing.filter((list) => {
      const itemName = list?.affiliatedUser?.affiliatedByCode;
      const itemClientName = list.affiliatedUser?.accountData?.account_number;
      const itemaccountId = list?.createdAt;
      return (
        itemName?.includes(searchTerm) ||
        itemClientName?.includes(searchTerm) ||
        itemaccountId?.includes(searchTerm)
      );
    });
  }

  const [field, setfields] = useState({
    search_date: new Date(),
  });
  const [field2, setfields2] = useState({
    startDate: "",
    endDate: "",
  });
  //pending affiliated info
  async function getAffiliatedInfoDetail() {
    try {
      let query = {
        ...filter,
        order: -1,
        url: "affiliated/getCommissionWithFilters",
        status: "PENDING",
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getList_(query));
      setaffiliateListing(res?.data?.data);
      setaffiliateCount(res?.data?.totalcount);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  //export affiliated info
  async function getExportAffiateInfo() {
    try {
      let query = {
        ...filter,
        url: "affiliated/getCommissionWithFiltersWithoutPage",
        status: "PENDING",
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getList_(query));
      let arrP = await paidTransacrionCSV(res?.data?.data);
      setCloseTradeCSV(arrP);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }
  //console.log(closeTradeCSV, "close");

  //transaction pending info
  async function getTransactionDetail() {
    try {
      let query = {
        ...filter,
        url: "affiliated/getWithFilters",
        status: "PENDING",
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getList_(query));
      settransactionListing(res?.data?.data);
      settransactionCount(res?.data?.totalcount);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  //transaction paid info
  async function getPaidTransactionDetail() {
    try {
      let query = {
        ...filter,
        url: "affiliated/getCommissionWithFilters",
        status: "APPROVED",
        startDate: field2.startDate,
        endDate: field2.endDate,
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getList_(query));
      setpaidListing(res?.data?.data);
      setpaidCount(res?.data?.totalcount);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  //transaction Existent paid info
  async function getPaidExistentDetail() {
    try {
      let query = {
        ...filter,
        url: "affiliated/getWithFilters",
        status: "APPROVED",
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getList_(query));
      setExistentListing(res?.data?.data);
      setExistentCount(res?.data?.totalcount);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  useEffect(() => {
    if (tabIndex === 0) {
      getTransactionDetail();
    }
    if (tabIndex === 1) {
      getAffiliatedInfoDetail();
    }
    if (tabIndex === 2) {
      getPaidExistentDetail();
    }
    if (tabIndex === 3) {
      getPaidTransactionDetail();
    }
    getExportAffiateInfo();
  }, [activePage, tabIndex, filter, field2]);

  const affiliateFunction = async (status, item) => {
    try {
      let query = {
        url: "affiliated/manageRequest",
        AffiliatedRequestId: item?._id,
        status: status,
      };
      let res = await dispatch(addData_(query));
      if (res?.data?.status === "success") {
        if (tabIndex === 0) {
          getTransactionDetail();
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleChangePicker = async (selectedDate, name) => {
    // Perform actions based on the selected date and field name
    let dateFormat = selectedDate ? selectedDate.format("DD/MM/YYYY") : "";
    setfields2((prev) => ({
      ...prev,
      [name]: dateFormat,
    }));
    // Rest of your code...
  };

  const affiliateFunction_ = async (status, item, type) => {
    try {
      let query = {
        url: "affiliated/manageCommissionRequest",
        AffiliatedRequestId: type === "single" ? item?._id : item,
        status: status,
      };
      let res = await dispatch(addData_(query));
      if (res?.data?.status === "success") {
        if (tabIndex === 1) {
          getAffiliatedInfoDetail();
          setTranscationRecord([]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handlePageChange = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };
  const [transcationRecord, setTranscationRecord] = useState([]);
  const [checkUncheck, setCheckUncheck] = useState(false);

  let arrIds = [];
  const getCheckedUserId = (e, id, type) => {
    const { checked } = e.target;
    if (type === "single") {
      // updateReveneFunction(id, "update_all_revenue")
    } else if (type === "multiple") {
      if (checked == true) {
        setTranscationRecord([...transcationRecord, id]);
        setCheckUncheck(!checkUncheck);
      } else {
        let index = transcationRecord.indexOf(id);
        if (index > -1) {
          transcationRecord.splice(index, 1);
          setTranscationRecord(transcationRecord);
          setCheckUncheck(!checkUncheck);
        }
      }
    } else {
      if (checked == true) {
        affiliateListing.map((item) => {
          return arrIds.push(item._id);
        });
        setCheckUncheck(!checkUncheck);
        setTranscationRecord(arrIds);
      } else {
        arrIds = [];
        setTranscationRecord([]);
        setCheckUncheck(!checkUncheck);
      }
    }
    return arrIds;
  };

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };
  const clearSerchFilter = () => {
    setFilter({
      ...filter,
      search: "",
      status: "",
    });
    setfields2({
      startDate: "",
      endDate: "",
    });
    setKey((prevKey) => prevKey + 1);
  };

  const handleSearchChange = (event) => {
    const inputValue = event.target.value;
    setFilter({
      ...filter,
      search: inputValue,
    });
  };

  const debouncedFetchData = useMemo(() => {
    return debounce(handleSearchChange, 300);
  }, []);
  useEffect(() => {
    return () => {
      debouncedFetchData.cancel();
    };
  }, []);

  return (
    <>
      <Helmet title={AFFILIATE.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{AFFILIATE.CURRENT_MODULE}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.affiliates}>{AFFILIATE.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Listing
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-12 grid-margin stretch-card">
          <div className="card graph_card">
            <div className="card-body">
              {/* <h4 className="card-title">
                <div className="row">
                  <div className="col-md-8">Filter By :</div>
                  <div class="form-group col-md-4">
                    <div class="input-group">
                      <input
                        className="form-control"
                        autoComplete="chrome-off"
                        name="searchUnique"
                        type="text"
                        placeholder="Search by name"
                      />
                      &nbsp;{" "}
                      <button
                        type="button"
                        className="btn btn-gradient-primary btn-sm"
                      >
                        <i class="mdi mdi-close-circle"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </h4> */}
              {transcationRecord?.length > 0 && (
                <div class="d-flex flex-wrap d-flex justify-content-end align-items-end">
                  <button
                    className="btn btn-sm btn-gradient-success"
                    style={{ cursor: "pointer" }}
                    title="Accept"
                    onClick={() =>
                      affiliateFunction_(
                        "APPROVED",
                        transcationRecord,
                        "multiple"
                      )
                    }
                  >
                    Accept
                  </button>
                  &nbsp;&nbsp;
                  <button
                    className="btn btn-sm btn-gradient-primary"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      affiliateFunction_(
                        "REJECTED",
                        transcationRecord,
                        "multiple"
                      )
                    }
                    title="Reject"
                  >
                    Reject
                  </button>
                </div>
              )}
              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList>
                  <Tab>Pending Affiliates</Tab>
                  <Tab>Pending Transactions</Tab>
                  <Tab>Existent Affiliates</Tab>
                  <Tab>Paid Transactions</Tab>
                </TabList>
                <TabPanel>
                  <div class="table-responsive">
                    <table className="table common_th">
                      <thead>
                        <tr>
                          <th
                            onClick={() =>
                              handleSortingFunc(
                                "list.userDetails.accountData.account_number"
                              )
                            }
                          >
                            {" "}
                            Account Id{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.userDetails.accountData.account_number"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.userDetails.accountData.account_number"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc("list.userDetails.createdAt")
                            }
                          >
                            {" "}
                            Registration Date{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.userDetails.createdAt"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.userDetails.createdAt"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() => handleSortingFunc("list.createdAt")}
                          >
                            {" "}
                            Request Date{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.createdAt"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.createdAt"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc("list.userDetails.name")
                            }
                          >
                            {" "}
                            Name{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.userDetails.name"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.userDetails.name"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th> Action </th>
                        </tr>
                      </thead>
                      <tbody>
                        {transactionListing?.length > 0 &&
                          transactionListing.map((list, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {
                                    list?.userDetails?.accountData
                                      ?.account_number
                                  }
                                </td>
                                <td>
                                  {list.userDetails?.createdAt
                                    ? DATE_FORMAT_TIME(
                                        list.userDetails?.createdAt
                                      )
                                    : "N/A"}
                                </td>
                                <td>
                                  {list?.createdAt
                                    ? DATE_FORMAT_TIME(list?.createdAt)
                                    : "N/A"}
                                </td>
                                <td>{list.userDetails?.name}</td>
                                <td>
                                  {list?.status === "PENDING" && (
                                    <>
                                      <button
                                        className="btn btn-sm btn-gradient-success"
                                        style={{ cursor: "pointer" }}
                                        title="Accept"
                                        onClick={() =>
                                          affiliateFunction("APPROVED", list)
                                        }
                                      >
                                        Accept
                                      </button>
                                      &nbsp;&nbsp;
                                      <button
                                        className="btn btn-sm btn-gradient-primary"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          affiliateFunction("REJECTED", list)
                                        }
                                        title="Reject"
                                      >
                                        Reject
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {transactionListing?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={transactionCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {transactionListing?.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  <div class="table-responsive">
                    <table className="table common_th">
                      <thead>
                        <tr>
                          <th>
                            <div className="d-flex align-items-center">
                              <input
                                type="checkbox"
                                name="select_box"
                                onClick={(e) => getCheckedUserId(e, "", "all")}
                                className="custom-control me-2"
                              />
                              Select
                            </div>
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc(
                                "list.affiliatedUser.affiliatedByCode"
                              )
                            }
                          >
                            {" "}
                            Affiliate Code{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.affiliatedUser.affiliatedByCode"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.affiliatedUser.affiliatedByCode"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc(
                                "list.affiliatedUser.accountData.account_number"
                              )
                            }
                          >
                            {" "}
                            Account id of client{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.affiliatedUser.accountData.account_number"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.affiliatedUser.accountData.account_number"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>

                          <th
                            onClick={() => handleSortingFunc("list.createdAt")}
                          >
                            {" "}
                            Trade Date{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.createdAt"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.createdAt"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc("list.orderData.symbol")
                            }
                          >
                            {" "}
                            Symbol{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.orderData.symbol"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.orderData.symbol"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("list.amount")}>
                            {" "}
                            Amount{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.amount"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.amount"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc("list.orderData.commission")
                            }
                          >
                            {" "}
                            Commission Amount{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.orderData.commission"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.orderData.commission"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th>
                            {" "}
                            Action{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-up`}
                            ></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {affiliateListing?.length > 0 &&
                          affiliateListing.map((list, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  <input
                                    type="checkbox"
                                    name="selection"
                                    className="custom-control"
                                    onClick={(e) =>
                                      getCheckedUserId(e, list._id, "multiple")
                                    }
                                    checked={
                                      transcationRecord.includes(list._id)
                                        ? "checked"
                                        : ""
                                    }
                                  />
                                </td>
                                <td>
                                  {list?.affiliatedUser?.affiliatedCode
                                    ? list?.affiliatedUser?.affiliatedCode
                                    : "N/A"}
                                </td>
                                <td>
                                  {list.affiliatedUser?.accountData
                                    ?.account_number
                                    ? list.affiliatedUser?.accountData
                                        ?.account_number
                                    : "N/A"}
                                </td>
                                <td>
                                  {list?.createdAt
                                    ? DATE_FORMAT_TIME(list?.createdAt)
                                    : "N/A"}
                                </td>
                                <td> {list?.symbol ? list?.symbol : "N/A"}</td>
                                <td>
                                  ${" "}
                                  {list?.amount && list?.treadAmount > 0
                                    ? `${parseFloat(list?.treadAmount)}`
                                    : "0"}
                                </td>
                                <td>
                                  {" "}
                                  ${" "}
                                  {list?.amount && list?.amount > 0
                                    ? `${parseFloat(list?.amount).toFixed(2)}`
                                    : "0"}
                                </td>
                                <td>
                                  {list?.status === "PENDING" && (
                                    <>
                                      <button
                                        className="btn btn-sm btn-gradient-success"
                                        style={{ cursor: "pointer" }}
                                        title="Accept"
                                        onClick={() =>
                                          affiliateFunction_(
                                            "APPROVED",
                                            list,
                                            "single"
                                          )
                                        }
                                      >
                                        Accept
                                      </button>
                                      &nbsp;&nbsp;
                                      <button
                                        className="btn btn-sm btn-gradient-primary"
                                        style={{ cursor: "pointer" }}
                                        onClick={() =>
                                          affiliateFunction_(
                                            "REJECTED",
                                            list,
                                            "single"
                                          )
                                        }
                                        title="Reject"
                                      >
                                        Reject
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {affiliateListing?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={affiliateCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {affiliateListing?.length === 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  <div class="table-responsive">
                    <table className="table common_th">
                      <thead>
                        <tr>
                          <th
                            onClick={() =>
                              handleSortingFunc(
                                "list.userDetails.affiliatedCode"
                              )
                            }
                          >
                            {" "}
                            Affiliate Code{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.userDetails.affiliatedCode"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.userDetails.affiliatedCode"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc(
                                "list.userDetails.affiliatedCode"
                              )
                            }
                          >
                            {" "}
                            Account Code{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.userDetails.accountData.account_number"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.userDetails.accountData.account_number"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc("list.userDetails.name")
                            }
                          >
                            {" "}
                            Name{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.userDetails.first_name"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.userDetails.first_name"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc(
                                "list.userDetails.affiliatedUserCount"
                              )
                            }
                          >
                            {" "}
                            Number of clients{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.userDetails.affiliatedUserCount"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.userDetails.affiliatedUserCount"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc("list.pendingCommission")
                            }
                          >
                            {" "}
                            Pending Commission{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.pendingCommission"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.pendingCommission"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc("list.approvedCommission")
                            }
                          >
                            {" "}
                            Paid Commission{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.approvedCommission"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.approvedCommission"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc("list.userDetails.createdAt")
                            }
                          >
                            {" "}
                            Registration Date{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.userDetails.createdAt"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.userDetails.createdAt"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc("list.userDetails.walletCredit")
                            }
                          >
                            {" "}
                            Total Ballanced{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.userDetails.walletCredit"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.userDetails.walletCredit"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {existentListing?.length > 0 &&
                          existentListing.map((list, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {list?.userDetails?.affiliatedCode
                                    ? list?.userDetails?.affiliatedCode
                                    : "N/A"}
                                </td>
                                <td>
                                  {list?.userDetails?.accountData
                                    ?.account_number
                                    ? list?.userDetails?.accountData
                                        ?.account_number
                                    : "N/A"}
                                </td>
                                <td>
                                  {list.userDetails?.name
                                    ? list.userDetails?.name
                                    : `${list.userDetails?.first_name} ${list.userDetails?.last_name}`}
                                </td>
                                <td>
                                  {list?.userDetails?.affiliatedUserCount
                                    ? list?.userDetails?.affiliatedUserCount
                                    : "0"}
                                </td>
                                <td>{list?.pendingCommission}</td>
                                <td>{list?.approvedCommission}</td>
                                <td>
                                  {list.userDetails?.createdAt
                                    ? DATE_FORMAT_TIME(
                                        list.userDetails?.createdAt
                                      )
                                    : "N/A"}
                                </td>
                                <td>
                                  $
                                  {list.userDetails?.walletCredit
                                    ? list.userDetails?.walletCredit
                                    : 0}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {existentListing?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={existentCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {existentListing?.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  <div className="d-flex align-items-center justify-content-between">
                    <h4 className="card-title">
                      <p className="invisible">hidden text</p>
                      <SearchFilter
                        searchValue={searchValue}
                        handleSearchChange={handleSearchChange}
                        clearSerchFilter={clearSerchFilter}
                        SearchText="Search by affiliate/account_id/trade date"
                        isSearchDate={false}
                        isExportButton={true}
                        isAddButton={false}
                        isInput={true}
                        filter={filter}
                        setFilter={setFilter}
                        isSelectOtherBox={false}
                        dropdownField="status"
                        csvData={closeTradeCSV}
                        setActivePage={setActivePage}
                        isStartEndDateFilter={true}
                        setfields={setfields}
                        field={field}
                        key={key}
                        setKey={setKey}
                        csvDataDownload_={""}
                      />
                    </h4>
                    <div class="fillte_main flex-wrap d-flex gap-3 align-items-end">
                      <div class="filter_drop_filter">
                        <h6>From Date</h6>
                        <Datetime
                          key={key}
                          value={field2.startDate || ""}
                          inputProps={{
                            placeholder: "DD/MM/YYYY",
                            readOnly: true,
                            className: "form-control class-time-date-time",
                          }}
                          name="startDate"
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          isValidDate={(current) => {
                            return current.isSameOrBefore(moment(), "day");
                          }}
                          onChange={(selectedDate) =>
                            handleChangePicker(selectedDate, "startDate")
                          }
                          closeOnSelect={true}
                        />
                      </div>
                      <div class="filter_drop_filter">
                        <h6>To Date</h6>
                        <Datetime
                          key={key}
                          value={field2.endDate || ""}
                          inputProps={{
                            placeholder: "DD/MM/YYYY",
                            readOnly: true,
                            className: "form-control class-time-date-time",
                          }}
                          name="endDate"
                          dateFormat="DD/MM/YYYY"
                          timeFormat={false}
                          isValidDate={(current) => {
                            // Parse the current date string in DD/MM/YYYY format to a moment object
                            const currentDate = moment(
                              current,
                              "DD/MM/YYYY",
                              true
                            );
                            // Parse the start date string in DD/MM/YYYY format to a moment object
                            const startDate = moment(
                              field2?.startDate,
                              "DD/MM/YYYY",
                              true
                            );
                            // Check if the current date is the same as or after the start date
                            return (
                              currentDate.isValid() &&
                              startDate.isValid() &&
                              currentDate.isSameOrAfter(startDate, "day")
                            );
                          }}
                          onChange={(selectedDate) =>
                            handleChangePicker(selectedDate, "endDate")
                          }
                          closeOnSelect={true}
                        />
                      </div>
                      <div class="filter_drop_filter">
                        <button
                          type="button"
                          class="btn btn-gradient-primary btn-lg px-3"
                          onClick={clearSerchFilter}
                        >
                          Clear Filter
                        </button>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive">
                    <table className="table common_th">
                      <thead>
                        <tr>
                          <th
                            onClick={() =>
                              handleSortingFunc(
                                "list.affiliatedUser.affiliatedByCode"
                              )
                            }
                          >
                            {" "}
                            Affiliate Code{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.affiliatedUser.affiliatedByCode"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.affiliatedUser.affiliatedByCode"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc(
                                "list.affiliatedUser.accountData.account_number"
                              )
                            }
                          >
                            {" "}
                            Account id of client{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.affiliatedUser.accountData.account_number"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy ===
                                  "list.affiliatedUser.accountData.account_number"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>

                          <th
                            onClick={() => handleSortingFunc("list.createdAt")}
                          >
                            {" "}
                            Trade Date{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.createdAt"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.createdAt"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc("list.orderData.symbol")
                            }
                          >
                            {" "}
                            Symbol{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.orderData.symbol"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.orderData.symbol"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("list.amount")}>
                            {" "}
                            Amount{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.amount"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.amount"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th
                            onClick={() =>
                              handleSortingFunc("list.orderData.commission")
                            }
                          >
                            {" "}
                            Commission Amount{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "list.orderData.commission"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "list.orderData.commission"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {listToDisplay?.length > 0 &&
                          listToDisplay.map((list, i) => {
                            return (
                              <tr key={i}>
                                <td>
                                  {list?.affiliatedUser?.affiliatedCode
                                    ? list?.affiliatedUser?.affiliatedCode
                                    : "N/A"}
                                </td>
                                <td>
                                  {list.affiliatedUser?.accountData
                                    ?.account_number
                                    ? list.affiliatedUser?.accountData
                                        ?.account_number
                                    : "N/A"}
                                </td>
                                <td>
                                  {list?.createdAt
                                    ? DATE_FORMAT_TIME(list?.createdAt)
                                    : "N/A"}
                                </td>
                                <td> {list?.symbol ? list?.symbol : "N/A"}</td>
                                <td>
                                  ${" "}
                                  {list?.treadAmount && list?.treadAmount > 0
                                    ? `${parseFloat(list?.treadAmount)}`
                                    : "0"}
                                </td>
                                <td>
                                  {" "}
                                  ${" "}
                                  {list?.amount && list?.amount > 0
                                    ? `${parseFloat(list?.amount).toFixed(2)}`
                                    : "0"}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {paidListing?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filter?.page}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={paidCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {paidListing?.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
