import React, { useState, useEffect, useCallback } from "react";
import Helmet from "react-helmet";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
//imported
import ReactPagination from "components/shared/ReactPagination";
import { USERS, itemsPerPage, PAGE_LIMIT } from "helpers/viewContent";
import {
  DATE_FORMAT_TIME,
  FORMAT_TEXT_VALUE,
  DATE_FORMAT_TIME_,
} from "helpers/common";
import { MAIN_LOGO_DUMMY_ } from "utils/constant";
import SendMessageModal from "./SendMessageModal";
import AddressModal from "./AddressModal";
import DocumentsModal from "./DocumentsModal";
import AssignMembers from "./AssignMembers";
import Commission from "./Commission";
import BalanceOperation from "./BalanceOperation";
import * as Path from "routes/paths";
import * as actions from "store/actions/index.js";
import { SIDEBAR_LOGIN_LOGO } from "utils/constant";
import Toggle from "react-toggle";
import "react-toggle/style.css";
import InfoModal from "./InfoModal";
import TradeModalInfo from "./TradeModalInfo";
import TradeModalInfoClose from "./TradeModalInfoClose";

import ProfileEdit from "./ProfileEdit";
//services
import {
  getDetail_,
  updateData_,
  getList_,
  addData_,
} from "store/services/commonService";
import BackofficeComment from "./BackofficeComment";
import OrderDataInfo from "./orderDataInfo";
import ImagePreviewModal from "../payments/ImagePreviewModal";
import NoImage from "../payments/No-Image-Placeholder.png";
import { convertToLocalString } from "utils/Utility";

const Listing = ({
  dispatch,
  itemsCountPerPage,
  totalItemsCount,
  loader,
  match,
  user,
  userProfile,
}) => {
  let editkey = match.params.id;
  const history = useHistory();

  let userRole = user?.user ? user?.user : userProfile;
  const [userPermissions, setUserPermissions] = useState({});
  console.log(userPermissions?.userCount, "userPermissionsuserPermissions");

  const [fields, setfields] = useState({
    affiliatedCode: "",
  });

  console.log(fields);
  //pagination
  const [activePage, setActivePage] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);
  const [tabIndex2, setTabIndex2] = useState(0);
  const [accountInfo, setAccountInfo] = useState("");
  const [editData, setEditData] = useState({});

  //info modal finance
  const [currentInfoValue, setCurrentInfoValue] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const handleInfoClose = () => {
    setShowInfoModal(false);
    setCurrentInfoValue(null);
  };
  const handleInfoShow = (list) => {
    setShowInfoModal(true);
    setCurrentInfoValue(list);
  };

  //trading info modal
  const [currentTradeValue, setCurrentTradeValue] = useState(null);
  const [showTradeModal, setShowTradeModal] = useState(false);
  const handleTradeClose = () => {
    setShowTradeModal(false);
    setCurrentTradeValue(null);
  };
  const handleTradeShow = (list) => {
    setShowTradeModal(true);
    setCurrentTradeValue(list);
  };
  //close trades
  const [currentCloseTradeValue, setCurrentCloseTradeValue] = useState(null);
  const [showCTradeModal, setShowCTradeModal] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const handleOpenDepositImage = (imageSrc) => {
    setSelectedImage(imageSrc);
  };
  const handleCTradeClose = () => {
    setShowCTradeModal(false);
    setCurrentCloseTradeValue(null);
  };
  const handleCTradeShow = (list) => {
    setShowCTradeModal(true);
    setCurrentCloseTradeValue(list);
  };
  const handleChangeaffiliatedCode = useCallback(
    (name) => (evt) => {
      setfields((prevState) => ({
        ...prevState,
        [name]: evt.target.value,
      }));
    },
    []
  );

  //show modal message
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  //show modal addresss
  const [showAddress, setShowAddress] = useState(false);
  const handleAddressClose = () => setShowAddress(false);
  const handleAddressShow = () => setShowAddress(true);
  const [isUpdateProfile, setisUpdateProfile] = useState(false);

  //edit profile modal
  const [editProfileModal, setEditProfileModal] = useState(false);
  const handleEditProfileClose = () => setEditProfileModal(false);
  const handleEditProfileShow = () => setEditProfileModal(true);

  //show modal documents
  const [showDocuments, setShowDocuments] = useState(false);
  const handleDocumentsClose = () => setShowDocuments(false);
  const handleDocumentsShow = () => setShowDocuments(true);

  //show assign modal message
  const [assignMember, setAssignMember] = useState(false);
  const handleAssignClose = () => setAssignMember(false);
  const handleAssignShow = () => setAssignMember(true);

  //show balance operation modal
  const [operation, setOperation] = useState(false);
  const handleOperationClose = () => setOperation(false);
  const handleOperationShow = () => setOperation(true);
  const data = [
    {
      symbol: `Group inc.`,
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
    {
      symbol: "NEO inc.",
      price: "$100",
      status: "completed",
      created_at: "2024-02-08 12:21:32",
    },
  ];
  const [listing, setListing] = useState(data);
  const [tradesListing, setTradesListing] = useState([]);
  const [totalCountTrade, settotalCountTrade] = useState(0);

  const [activities, setActivities] = useState([]);
  const [finances, setfinances] = useState([]);
  console.log(finances, "finances");

  const [affiliateListing, setAffiliateListing] = useState([]);
  const [affiliateCount, setaffiliateCount] = useState(0);

  const [financesCount, setfinancesCount] = useState(0);
  const [tradeTabIndex, setTradeTabIndex] = useState(0);
  const [closeTrades, setCloseTrades] = useState([]);

  async function getDataList() {
    try {
      let query = {
        url: "getUserById",
        id: editkey,
      };
      setisUpdateProfile(false);
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getDetail_(query));
      setEditData(res?.data);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  useEffect(() => {
    if (editkey) {
      getDataList();
      getTradesInfoDetail();
      getActivitiesData();
    }
  }, [activePage, tabIndex, isUpdateProfile]);

  const indexOfLastItem = activePage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems =
    activities?.tradeActivity?.length > 0 &&
    activities?.tradeActivity?.slice(indexOfFirstItem, indexOfLastItem);

  const currentNoneTradeItems =
    activities?.nonTradeActivity?.length > 0 &&
    activities?.nonTradeActivity?.slice(indexOfFirstItem, indexOfLastItem);
  //pagination
  const handlePageChange = (page) => {
    setActivePage(page);
    window.scrollTo(0, 0);
  };

  //verify status setting data
  const VerifyStatusFunc = async (status, type) => {
    try {
      let obj = {
        url: "editUser",
        userId: editData?._id,
      };
      if (type === "personal_info") {
        obj.personal_info = status;
      } else if (type === "profile_info") {
        obj.profile_info = status;
      } else if (type === "documents") {
        obj.documents = status;
      } else if (type === "biometric") {
        obj.biometric = status;
      } else if (type === "employment") {
        obj.employment = status;
      }
      await dispatch(updateData_(obj));
      getDataList();
    } catch (err) {
      console.log(err);
    }
  };

  //verify trades setting data toogle buttons
  const VerifyStatusTradingFunc = async (name) => {
    try {
      let obj = {
        url: "editUser",
        userId: editData?._id,
      };
      if (name === "isTrade") {
        obj.isTrade = !editData?.isTrade;
      }
      if (name === "isDeposit") {
        obj.isDeposit = !editData?.isDeposit;
      }
      if (name === "isWithdraw") {
        obj.isWithdraw = !editData?.isWithdraw;
      }
      if (name === "isClosed") {
        obj.isClosed = !editData?.isClosed;
      }
      await dispatch(updateData_(obj));
      getDataList();
    } catch (err) {
      console.log(err);
    }
  };

  //verify user aprroved alpaca status
  const VerifyAlpacaStatusFunc = async (status) => {
    try {
      let obj = {
        url: "update/status",
        userId: editData?._id,
        status: status,
      };
      await dispatch(updateData_(obj));
      getDataList();
    } catch (err) {
      console.log(err);
    }
  };

  async function getActivitiesData() {
    try {
      let query = {
        url: "account/activities",
        id: editkey,
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getDetail_(query));
      setActivities(res?.data);
      setAccountInfo(res?.data?.accountNumber);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  const [filter, setFilter] = useState({
    page: 1,
    limit: itemsPerPage,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });

  //trades info
  async function getTradesInfoDetail() {
    try {
      let query = {
        ...filter,
        url: "getUserTradesWithFilter",
        userId: editkey,
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getList_(query));
      setTradesListing(res?.data?.data);
      settotalCountTrade(res?.data?.totalcount);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  //affiliated info
  async function getAffiliatedInfoDetail() {
    try {
      let query = {
        ...filter,
        url: "affiliated/getWithFilters",
        affiliatedCodeUser: editkey,
        status: "APPROVED",
      };
      dispatch(actions.persist_store({ loader: true }));
      let res = await dispatch(getList_(query));
      setAffiliateListing(res?.data?.data);
      setaffiliateCount(res?.data?.totalcount);
      dispatch(actions.persist_store({ loader: false }));
    } catch (err) {
      dispatch(actions.persist_store({ loader: false }));
      console.log(err);
    }
  }

  //pagination
  const handlePageChangeTrade = (page) => {
    setFilter({
      ...filter,
      page: page,
    });
    window.scrollTo(0, 0);
  };

  //payment info finances
  const [filterT, setFilterT] = useState({
    page: 1,
    limit: itemsPerPage,
    search: "",
    status: "",
    order: 1,
    orderBy: "createdAt",
  });

  const fetchData = async () => {
    try {
      let query = {
        ...filterT,
        url: "getUserTransactionsWithFilter",
        type: ["withdrawl", "incoming"],
        isAdmin: true,
        userId: editkey,
      };
      let res = await dispatch(getList_(query));
      setfinances(res?.data?.data);
      setfinancesCount(res?.data?.totalcount);
    } catch (err) {
      console.log(err);
    }
  };

  const closeTradeData = async () => {
    try {
      let query = {
        ...filter,
        url: "get/closeCancelTrade",
        userId: editkey,
      };
      let res = await dispatch(getList_(query));
      setCloseTrades(res?.data?.data);
      settotalCountTrade(res?.data?.totalcount);
    } catch (err) {
      console.log(err);
    }
  };
  function truncateString(str) {
    if (str.length <= 8) {
      return str; // If the string is 8 characters or less, return it as is
    }

    const firstFour = str.slice(0, 8);
    const lastFour = str.slice(-8);

    return `${firstFour}....${lastFour}`;
  }
  useEffect(() => {
    if (tradeTabIndex === 1) {
      closeTradeData();
    } else if (tradeTabIndex === 0 && tabIndex === 1) {
      getTradesInfoDetail();
    }
  }, [tradeTabIndex, tabIndex, filter]);

  const handlePageChangeT = (page) => {
    setFilterT({
      ...filterT,
      page: page,
    });
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    if (editkey) {
      getTradesInfoDetail();
      getAffiliatedInfoDetail();
      fetchData();
    }
  }, [tabIndex, filter, filterT]);

  const renderTradesGraph = (symbol) => {
    const url = `https://www.tradingview.com/symbols/${symbol}/`;
    window.open(url, "_blank");
  };

  useEffect(() => {
    let userPermission =
      userProfile && userProfile?.permissions?.length > 0
        ? JSON.parse(userProfile?.permissions)
        : {};
    let userData =
      user?.user?.permissions && user?.user?.permissions?.length > 0
        ? JSON.parse(user?.user?.permissions)
        : userPermission;
    setUserPermissions(userData);
  }, [userRole]);

  const handleSortingFunc = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilterT({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilterT({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };
  const handleSortingFuncT = (value) => {
    // Check if the clicked column is the same as the current orderBy column
    if (filter.orderBy === value) {
      // If yes, toggle the order (ascending to descending or vice versa)
      setFilter({
        ...filter,
        order: filter.order === -1 ? 1 : -1,
      });
    } else {
      // If no, set the orderBy to the clicked column and order to ascending
      setFilter({
        ...filter,
        order: -1,
        orderBy: value,
      });
    }
  };

  const getStatus = (status) => {
    if (status === "accepted") {
      return <label className={"badge badge-success"}>{status}</label>;
    } else if (status === "pending") {
      return <label className={"badge badge-pending"}>{status}</label>;
    } else if (status === "closed") {
      return <label className={"badge badge-close"}>{status}</label>;
    } else {
      return <label className={"badge badge-danger"}>{status}</label>;
    }
  };

  function formatNumber(value) {
    if (value >= 1e7) {
      // Convert to crores (1 crore = 10 million)
      return (value / 1e7).toFixed(2) + "Cr";
    } else if (value >= 1e5) {
      // Convert to lakhs (1 lakh = 100 thousand)
      return (value / 1e5).toFixed(2) + "L";
    } else if (value >= 1e3) {
      // Convert to thousands
      return (value / 1e3).toFixed(2) + "K";
    } else {
      return value.toString();
    }
  }

  const affiliateFunction = async (status) => {
    try {
      let query = {
        url: "affiliated/manageUsers",
        userId: editData?._id,
        affiliatedCode: parseInt(fields?.affiliatedCode),
        status: status,
      };
      let res = await dispatch(addData_(query));
      getDataList();
    } catch (err) {
      console.log(err);
    }
  };

  console.log(affiliateListing, "affiliateListing");
  return (
    <>
      <Helmet title={USERS.CURRENT_MODULE} />
      <div className="page-header">
        <h3 className="page-title">{USERS.CURRENT_VIEW}</h3>
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to={Path.users}>{USERS.CURRENT_MODULE}</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Detail
            </li>
          </ol>
        </nav>
      </div>
      <div className="row">
        <div className="col-lg-4 grid-margin stretch-card profile_sider">
          <div className="card graph_card border_custom">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h4 className="card-title">Profile</h4>
                {editData?.status === "pending" ? (
                  // <button
                  //   type="submit"
                  //   className="btn btn-sm btn-gradient-success"
                  //   title="Approve"
                  //   onClick={() => VerifyAlpacaStatusFunc("approved")}
                  // >
                  //   Approve
                  // </button>

                  <label className="badge badge-pending">
                    {FORMAT_TEXT_VALUE(editData?.status)}
                  </label>
                ) : editData?.status === "approved" ? (
                  <label className="badge badge-success">
                    {FORMAT_TEXT_VALUE(editData?.status)}
                  </label>
                ) : editData?.status === "rejected" ? (
                  <label className="badge badge-danger">
                    {FORMAT_TEXT_VALUE(editData?.status)}
                  </label>
                ) : (
                  <></>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <img
                    src={
                      editData?.profileImage
                        ? editData?.profileImage
                        : MAIN_LOGO_DUMMY_
                    }
                    className="mb-2 rounded img-view user-profileimage"
                    alt="image"
                  />
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.first_name
                      ? `${editData?.first_name} ${editData?.last_name}`
                      : "N/A"}
                  </label>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="col-md-6">
                  <p>Registration Date</p>
                  <label className="col-sm-12 col-form-label">
                    Status Sumsub
                  </label>
                  <label className="col-sm-12 col-form-label">
                    Status Alpaca
                  </label>
                </div>
                <div className="col-md-6">
                  <p>{DATE_FORMAT_TIME_(editData?.createdAt)}</p>
                  <label className="col-sm-12 col-form-label">
                    {editData?.status
                      ? FORMAT_TEXT_VALUE(editData?.status)
                      : "N/A"}
                  </label>
                  <label className="col-sm-12 col-form-label">
                    {editData?.isAlpacaApproved ? "Apporved" : "Pending"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <p>Send on email : </p>
                </div>
                <div className="col-md-6">
                  <button
                    type="button"
                    className="btn btn-sm btn-gradient-primary button_red"
                    title="Send Message"
                    onClick={handleShow}
                  >
                    Send Message
                  </button>
                </div>
              </div>
              {/* {editData?.role==='ADMIN' && ( */}
              <div className="row mt-3">
                <div className="col-md-6">
                  <p>Assign Member : </p>
                </div>
                {/* {editData?.assignId !== null && */}
                <div className="col-md-6">
                  <h6>
                    <b>{editData?.assignDetails?.name}</b>
                  </h6>
                  <button
                    type="button"
                    className="btn btn-sm btn-gradient-primary button_red"
                    title="Assign Member"
                    onClick={
                      userRole?.role === "SUBADMIN" &&
                      userPermissions.hasOwnProperty("userCount") &&
                      userPermissions?.userCount?.update
                        ? handleAssignShow
                        : userRole?.role === "ADMIN"
                        ? handleAssignShow
                        : console.log("#")
                    }
                  >
                    {editData?.assignId ? "Ressign" : "Assign"} Member
                  </button>
                </div>
                {/* } */}
                {/* <div className="col-md-6">
                                </div> */}
              </div>
              {/* )} */}
              {(user?.role === "ADMIN" ||
                (user?.role === "SUBADMIN" &&
                  userPermissions?.transactionCount
                    ?.balance_operation_pay)) && (
                <>
                  <div className="row mt-3">
                    <div className="col-md-6">
                      <p>Balance Opreation: </p>
                    </div>
                    <div className="col-md-6">
                      <button
                        type="button"
                        disabled={editData?.accountId === null ? true : false}
                        className="btn btn-sm btn-gradient-primary button_red"
                        title={
                          editData?.accountId === null
                            ? "Account id is null"
                            : "Pay"
                        }
                        onClick={() => handleOperationShow()}
                      >
                        {" "}
                        Pay{" "}
                      </button>
                    </div>
                  </div>
                </>
              )}
              <hr />
              <div className="d-flex align-items-center justify-content-between">
                <h5>Personal Information</h5>
                <div className="p-0 d-flex align-items-center justify-content-end">
                  <button
                    type="button"
                    className="edit_card_cta btn btn-sm btn-gradient-primary"
                    title="Edit"
                    onClick={
                      userRole?.role === "SUBADMIN" &&
                      userPermissions.hasOwnProperty("userCount") &&
                      userPermissions?.userCount?.update
                        ? handleEditProfileShow
                        : userRole?.role === "ADMIN"
                        ? handleEditProfileShow
                        : console.log("#")
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_252_1049)">
                        <path
                          d="M15.2933 0.706709C14.8401 0.254173 14.2258 0 13.5853 0C12.9449 0 12.3306 0.254173 11.8773 0.706709L0 12.584V16H3.416L15.2933 4.12271C15.7457 3.66939 15.9998 3.05513 15.9998 2.41471C15.9998 1.77429 15.7457 1.16003 15.2933 0.706709ZM2.86667 14.6667H1.33333V13.1334L10.2067 4.26671L11.74 5.80004L2.86667 14.6667ZM14.3507 3.18004L12.6793 4.85138L11.1493 3.31804L12.82 1.64938C13.0233 1.44604 13.2991 1.33181 13.5867 1.33181C13.8742 1.33181 14.15 1.44604 14.3533 1.64938C14.5567 1.85271 14.6709 2.12849 14.6709 2.41604C14.6709 2.7036 14.5567 2.97938 14.3533 3.18271L14.3507 3.18004Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_252_1049">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                  {editData?.personal_info ? (
                    <label className="badge badge-success">Passed</label>
                  ) : (
                    <button
                      type="submit"
                      onClick={() =>
                        userRole?.role === "SUBADMIN" &&
                        userPermissions.hasOwnProperty("userCount") &&
                        userPermissions?.userCount?.update
                          ? VerifyStatusFunc(true, "personal_info")
                          : userRole?.role === "ADMIN"
                          ? VerifyStatusFunc(true, "personal_info")
                          : console.log("#")
                      }
                      className="btn btn-sm btn-gradient-primary"
                      title="Incomplete"
                    >
                      Incomplete
                    </button>
                  )}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">First Name</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.first_name ? `${editData?.first_name}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">Last Name</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.last_name ? `${editData?.last_name}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">Given Name</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.given_name ? `${editData?.given_name}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">Email</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.email ? editData?.email : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    Mobile Number
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.mobileNumber
                      ? `${editData?.countryCode} ${editData?.mobileNumber}`
                      : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="">Wallet</label>
                </div>
                <div className="col-md-6">
                  <label>
                    {editData?.walletCredit != null &&
                    !isNaN(editData?.walletCredit)
                      ? "$" + parseInt(editData?.walletCredit).toFixed(2)
                      : "$0"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    Nationality
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.nationality ? `${editData?.nationality}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    Date of Birth
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.dob ? `${editData?.dob}` : "N/A"}
                  </label>
                </div>
              </div>
              <hr />
              <div className="">
                <div className="d-flex align-items-center justify-content-between">
                  <h5>Profile Info</h5>
                  <div className="p-0 d-flex align-items-center justify-content-end">
                    <button
                      type="button"
                      className="edit_card_cta btn btn-sm btn-gradient-primary"
                      title="Edit"
                      onClick={
                        userRole?.role === "SUBADMIN" &&
                        userPermissions.hasOwnProperty("userCount") &&
                        userPermissions?.userCount?.update
                          ? handleAddressShow
                          : userRole?.role === "ADMIN"
                          ? handleAddressShow
                          : console.log("#")
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                      >
                        <g clip-path="url(#clip0_252_1049)">
                          <path
                            d="M15.2933 0.706709C14.8401 0.254173 14.2258 0 13.5853 0C12.9449 0 12.3306 0.254173 11.8773 0.706709L0 12.584V16H3.416L15.2933 4.12271C15.7457 3.66939 15.9998 3.05513 15.9998 2.41471C15.9998 1.77429 15.7457 1.16003 15.2933 0.706709ZM2.86667 14.6667H1.33333V13.1334L10.2067 4.26671L11.74 5.80004L2.86667 14.6667ZM14.3507 3.18004L12.6793 4.85138L11.1493 3.31804L12.82 1.64938C13.0233 1.44604 13.2991 1.33181 13.5867 1.33181C13.8742 1.33181 14.15 1.44604 14.3533 1.64938C14.5567 1.85271 14.6709 2.12849 14.6709 2.41604C14.6709 2.7036 14.5567 2.97938 14.3533 3.18271L14.3507 3.18004Z"
                            fill="black"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_252_1049">
                            <rect width="16" height="16" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </button>
                    {editData?.profile_info ? (
                      <label class="badge badge-success">Passed</label>
                    ) : (
                      <button
                        type="submit"
                        onClick={() =>
                          userRole?.role === "SUBADMIN" &&
                          userPermissions.hasOwnProperty("userCount") &&
                          userPermissions?.userCount?.update
                            ? VerifyStatusFunc(true, "profile_info")
                            : userRole?.role === "ADMIN"
                            ? VerifyStatusFunc(true, "profile_info")
                            : console.log("#")
                        }
                        className="btn btn-sm btn-gradient-primary"
                        title="Incomplete"
                      >
                        Incomplete
                      </button>
                    )}
                  </div>
                </div>
                <div className="col-md-8">
                  <h5>Address</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <label className="col-sm-12 col-form-label">
                    {editData?.location ? editData?.location : "N/A"}
                  </label>
                </div>
              </div>
              <hr />
              <div className="">
                <div className="d-flex align-items-center justify-content-between">
                  <h5>Affiliate</h5>
                </div>
                <div className="col-md-8">
                  <p>
                    Affiliated Code :{" "}
                    {editData?.isAffiliated ? editData?.affiliatedCode : "N/A"}
                  </p>
                </div>
                <div className="col-md-8">
                  <p>
                    Affiliated User Count :{" "}
                    {editData?.isAffiliated
                      ? editData?.affiliatedUserCount
                      : "0"}
                  </p>
                </div>

                <div className="col-md-12 d-flex gap-10">
                  <p>Affiliated by User:</p>
                  {editData?.affiliatedByUser?.email ? (
                    <a
                      style={{ cursor: "pointer" }}
                      href={`/admin/user/detail/${editData?.affiliatedByUser?._id}`}
                    >
                      {editData?.affiliatedByUser?.email}
                    </a>
                  ) : (
                    <p>N/A</p>
                  )}
                </div>

                <div className="row">
                  {!editData?.isAffiliated && (
                    <>
                      <div className="form-group  col-md-12">
                        <label for="exampleInputName1">Affiliated Code*</label>
                        <div className="col-md-12">
                          <input
                            type="number"
                            className="form-control"
                            id="exampleInputName1"
                            placeholder="Enter affiliatedCode"
                            name="affiliatedCode"
                            min={0}
                            onChange={handleChangeaffiliatedCode(
                              "affiliatedCode"
                            )}
                            value={fields.affiliatedCode}
                          />
                        </div>
                      </div>

                      <div className="col-md-4">
                        <button
                          type="submit"
                          onClick={() => affiliateFunction("APPROVED")}
                          className="btn btn-sm btn-gradient-success"
                          title="Assign"
                          disabled={
                            fields?.affiliatedCode === "" ? true : false
                          }
                        >
                          Assign
                        </button>
                      </div>
                    </>
                  )}
                  {editData?.isAffiliated && (
                    <div className="col-md-4">
                      <button
                        type="submit"
                        onClick={() => affiliateFunction("REJECTED")}
                        className="btn btn-sm btn-gradient-primary"
                        title="Reject"
                      >
                        Reject
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-8 grid-margin stretch-card">
          <div className="card graph_card border_custom">
            <div className="card-body ">
              <div className="managerUserCard">
                <div className="row">
                  {/* text-truncate */}
                  <div className="col-lg-6 col-md-6 col-sm-6 border-end">
                    <ul className="list-unstyled ps-0 mb-0">
                      <li className="p-1 d-flex align-itmes-start gap-2">
                        <span className="">Acct ID -</span>

                        <span
                          className="
                          fw-bold"
                          // style={{ maxWidth: 100 }}
                        >
                          {editData?.accountData?.account_number || ""}
                        </span>
                      </li>
                      <li className="p-1 d-flex align-itmes-start gap-2">
                        <span className="">User ID -</span>

                        <span
                          className="
                          fw-bold"
                          // style={{ maxWidth: 100 }}
                        >
                          {editData?._id || ""}
                        </span>
                      </li>
                      <li className="p-1 d-flex align-itmes-start gap-2">
                        <span className="">C. Bal.$ -</span>
                        <span className="fw-bold">
                          {" "}
                          {editData?.walletCredit}
                          {/* {
                            editData?.walletCredit != null &&
                            !isNaN(editData?.walletCredit)
                              ? formatNumber(editData?.walletCredit)
                              : "0"
                            // ? parseFloat(editData?.walletCredit) % 1 !== 0
                            //   ? editData?.walletCredit.toFixed(2)
                            //   : editData?.walletCredit
                            // : "0"
                          } */}
                        </span>
                      </li>
                      <li className="p-1 d-flex align-itmes-start gap-2 lastItem">
                        <span className="">C. Equi.$ -</span>
                        <span className="fw-bold">
                          {/* {
                            editData?.equity != null && !isNaN(editData?.equity)
                              ? formatNumber(editData?.equity)
                              : "0"
                            // ? parseFloat(editData?.equity) % 1 !== 0
                            //   ? editData?.equity.toFixed(2)
                            //   : editData?.equity
                            // : "0"
                          } */}
                          {editData?.equity}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6 border-end">
                    <ul className="list-unstyled ps-0 mb-0">
                      <li className="p-1 d-flex align-itmes-start gap-2">
                        <span className=""> Dep. T.$ -</span>
                        <span className="fw-bold">
                          {convertToLocalString(editData?.depositTotal)}
                          {/* {editData?.depositTotal
                            ? formatNumber(editData?.depositTotal)
                            : "0"} */}
                        </span>
                      </li>
                      <li className="p-1 d-flex align-itmes-start gap-2">
                        <span className=""> Wid. T. $ -</span>
                        <span className="fw-bold">
                          {convertToLocalString(editData?.withdrawalTotal)}
                          {/* {
                            editData?.withdrawalTotal != null &&
                            !isNaN(editData?.withdrawalTotal)
                              ? formatNumber(editData?.withdrawalTotal)
                              : "0"
                            // ? parseInt(editData?.withdrawalTotal).toFixed(2)
                            // : "0"
                          } */}
                        </span>
                      </li>
                      <li className="p-1 d-flex align-itmes-start gap-2 lastItem">
                        <span className=""> Net Dep. $ -</span>
                        <span className="fw-bold">
                          {convertToLocalString(
                            editData?.depositTotal - editData?.withdrawalTotal
                          )}
                          {/* {editData?.depositTotal !== null &&
                          editData?.withdrawalTotal !== null
                            ? formatNumber(
                                editData?.depositTotal -
                                  editData?.withdrawalTotal
                              )
                            : "0"} */}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <br />
                  <div className="col-lg-6 col-md-6 col-sm-6 border-end">
                    <ul className="list-unstyled ps-0 mb-0">
                      <li className="p-1 d-flex align-itmes-start gap-2">
                        <span className=""> Dep. T. IDR -</span>
                        <span className="fw-bold">
                          {convertToLocalString(editData?.depositTotal * editData?.exchangeRates)}
   

                          {/* {editData?.depositTotal
                            ? formatNumber(editData?.depositTotal * 16000)
                            : "0"} */}
                        </span>
                      </li>
                      <li className="p-1 d-flex align-itmes-start gap-2">
                        <span className=""> Wid. T. IDR -</span>
                        <span className="fw-bold">
                          {convertToLocalString(editData?.withdrawalTotal * editData?.exchangeRates)}

                          {/* {editData?.withdrawalTotal != null &&
                          !isNaN(editData?.withdrawalTotal)
                            ? formatNumber(editData?.withdrawalTotal * 16000)
                            : "0"} */}
                        </span>
                      </li>
                      <li className="p-1 d-flex align-itmes-start gap-2 lastItem">
                        <span className=""> Net Dep. IDR -</span>
                        <span className="fw-bold">
                          {convertToLocalString(
                            editData?.depositTotal * editData?.exchangeRates -
                              editData?.withdrawalTotal *
                                editData?.exchangeRates
                          )}
                          {/* {editData?.depositTotal !== null &&
                          editData?.withdrawalTotal !== null
                            ? formatNumber(
                                editData?.depositTotal * 16000 -
                                  editData?.withdrawalTotal * 16000
                              )
                            : "0"} */}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <ul className="list-unstyled ps-0 mb-0">
                      <li className="p-1 d-flex align-itmes-start gap-2">
                        <span className=""> T. Count/Shares $ -</span>
                        <span className="fw-bold">
                          {(editData?.tradeCount ? editData.tradeCount : "0") +
                            "/" +
                            (editData?.shareCount
                              ? editData.shareCount
                              : "0")}
                        </span>
                      </li>
                      <li className="p-1 d-flex align-itmes-start gap-2">
                        <span className=""> T. Sum $ -</span>
                        <span className="fw-bold">
                          {convertToLocalString(editData?.openPositionValue)}
                          {/* {editData?.openPositionValue != null &&
                          !isNaN(editData?.openPositionValue)
                            ? formatNumber(editData?.openPositionValue)
                            : // ? parseInt(editData?.openPositionValue).toFixed(2)
                              "0"} */}
                        </span>
                      </li>
                      <li className="p-1 d-flex align-itmes-start gap-2 lastItem">
                        <span className=""> Value at T. -</span>
                        <span className="fw-bold">
                          {convertToLocalString(editData?.openPositionValue)}

                          {/* {editData?.openPositionValue
                            ? formatNumber(editData?.openPositionValue)
                            : "0"} */}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <Tabs
                selectedIndex={tabIndex}
                onSelect={(index) => setTabIndex(index)}
              >
                <TabList>
                  <Tab>Finances</Tab>
                  <Tab>Trades</Tab>
                  <Tab>Account Activity</Tab>
                  <Tab>Affilated Commission</Tab>
                </TabList>
                <TabPanel>
                  <h4 className="card-title">Finances Detail</h4>
                  <div class="table-responsive fixed_height">
                    <table className="table">
                      <thead>
                        <tr>
                          <th
                            onClick={() => handleSortingFunc("transactionId")}
                          >
                            {" "}
                            Transaction Id{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionId"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionId"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                          <th>Type</th>

                          {/* <th
                            onClick={() => handleSortingFunc("transactionType")}
                          >
                            {" "}
                            Transaction Type
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionType"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "transactionType"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th> */}
                          <th>Transaction File</th>
                          <th onClick={() => handleSortingFunc("amount")}>
                            {" "}
                            Amount{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "amount"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "amount"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                          <th onClick={() => handleSortingFunc("status")}>
                            {" "}
                            Status{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "status"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "status"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {finances?.length > 0 &&
                          finances?.map((list, i) => {
                            return (
                              <tr
                                key={i}
                                className="cursor-pointer"
                                title="View"
                              >
                                <td onClick={() => handleInfoShow(list)}>
                                  {list?.transactionId
                                    ? list?.transactionId
                                    : "N/A"}
                                </td>
                                <td>
                                  {list?.type == "incoming"
                                    ? "deposit"
                                    : list?.type}
                                </td>
                                {/* <td>{list?.transactionType}</td> */}
                                {list?.transactionFile ? (
                                  <td
                                    onClick={() => {
                                      handleOpenDepositImage(
                                        list?.transactionFile
                                      );
                                    }}
                                  >
                                    <img
                                      src={
                                        list?.transactionFile
                                          ? list?.transactionFile
                                          : NoImage
                                      }
                                    />
                                  </td>
                                ) : (
                                  <td
                                    onClick={() => {
                                      handleOpenDepositImage(NoImage);
                                    }}
                                  >
                                    <img
                                      src={
                                        list?.transactionFile
                                          ? list?.transactionFile
                                          : NoImage
                                      }
                                    />
                                  </td>
                                )}
                                <td>
                                  ${list?.amount ? list.amount.toFixed(2) : "0"}
                                </td>

                                <td>
                                  <label
                                    className={`${
                                      list.status == "approved"
                                        ? "badge-success payment_status"
                                        : list.status == "pending"
                                        ? "badge-warning"
                                        : list.status == "rejected"
                                        ? "badge-danger"
                                        : ""
                                    } badge rounded-pill`}
                                  >
                                    {FORMAT_TEXT_VALUE(list?.status)}
                                  </label>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>

                    {finances?.length == 0 && (
                      <span className="py-2 spanclass">No data found...</span>
                    )}
                  </div>
                  {finances?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={filterT?.page}
                        itemsCountPerPage={itemsPerPage}
                        totalItemsCount={financesCount}
                        handlePageChange={handlePageChangeT}
                      />
                    </div>
                  )}
                </TabPanel>

                <TabPanel>
                  <h4 className="card-title">Trades Detail</h4>

                  <Tabs
                    selectedIndex={tradeTabIndex}
                    onSelect={(index) => setTradeTabIndex(index)}
                  >
                    <TabList>
                      <Tab>Open</Tab>
                      <Tab>Close</Tab>
                    </TabList>
                    <TabPanel>
                      <div class="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> Trade </th>
                              <th> Symbol </th>
                              <th onClick={() => handleSortingFuncT("qty")}>
                                {" "}
                                Qty.{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "qty"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "qty"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th>
                              <th onClick={() => handleSortingFuncT("status")}>
                                {" "}
                                Status{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "status"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "status"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th>
                              <th
                                onClick={() =>
                                  handleSortingFuncT("unrealized_pl")
                                }
                              >
                                {" "}
                                P/L{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "unrealized_pl"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "unrealized_pl"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th>
                              <th
                                onClick={() =>
                                  handleSortingFuncT("avg_entry_price")
                                }
                              >
                                Average Price{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "avg_entry_price"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "avg_entry_price"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th>
                              <th
                                onClick={() =>
                                  handleSortingFuncT("boughtPrice")
                                }
                              >
                                {" "}
                                Bought Price{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "boughtPrice"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "boughtPrice"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th>
                              <th
                                onClick={() =>
                                  handleSortingFuncT("current_price")
                                }
                              >
                                {" "}
                                Current Price{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "current_price"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "current_price"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th>
                              <th onClick={() => handleSortingFuncT("amount")}>
                                {" "}
                                Amount{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "amount"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "amount"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {tradesListing?.length > 0 &&
                              tradesListing.map((list, i) => {
                                return (
                                  <tr
                                    key={i}
                                    onClick={() => handleTradeShow(list)}
                                    className="cursor-pointer"
                                    title="View"
                                  >
                                    <td className="d-flex align-items-center">
                                      <div className="symbol_img me-2">
                                        <img
                                          src={
                                            list?.imgUrl
                                              ? list?.imgUrl
                                              : SIDEBAR_LOGIN_LOGO
                                          }
                                          alt=""
                                          className="img-fluid"
                                        />
                                      </div>
                                      {list?.shortName
                                        ? list?.shortName
                                        : list?.name}
                                    </td>
                                    <td
                                      onClick={() =>
                                        renderTradesGraph(list?.symbol)
                                      }
                                      className="cursor-pointer"
                                      title="View"
                                    >
                                      <b>{list?.symbol}</b>
                                    </td>
                                    <td>{list.qty ? list.qty : "0"}</td>
                                    {/* <td>
                                      $
                                      {parseFloat(list?.requestPrice) % 1 !== 0
                                        ? list?.requestPrice?.toFixed(2)
                                        : "0"}
                                    </td> */}
                                    <td>
                                      <label className="badge badge-success payment_status">
                                        {FORMAT_TEXT_VALUE(list.status)}
                                      </label>
                                    </td>
                                    <td>
                                      $
                                      {list?.unrealized_pl
                                        ? (list?.unrealized_pl).toFixed(2)
                                        : "0"}
                                    </td>
                                    <td>
                                      $
                                      {list.avg_entry_price
                                        ? list.avg_entry_price.toFixed(2)
                                        : "0"}
                                    </td>
                                    <td>
                                      $
                                      {list.avg_entry_price
                                        ? list.avg_entry_price.toFixed(2)
                                        : "0"}
                                    </td>
                                    <td>
                                      $
                                      {list.current_price
                                        ? list.current_price.toFixed(2)
                                        : "0"}
                                    </td>
                                    <td>
                                      $
                                      {list.amount
                                        ? list.amount.toFixed(2)
                                        : "0"}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                        {tradesListing?.length == 0 && (
                          <span className="py-2 spanclass">
                            No data found...
                          </span>
                        )}
                      </div>
                      {tradesListing?.length > 0 && (
                        <div className="pagination-box-review">
                          <ReactPagination
                            activePage={filter?.page}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={totalCountTrade}
                            handlePageChange={handlePageChangeTrade}
                          />
                        </div>
                      )}
                    </TabPanel>
                    <TabPanel>
                      <div class="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th
                                onClick={() => handleSortingFunc("user_name")}
                              >
                                Client's Name{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "user_name"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "user_name"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                              <th
                                onClick={() => handleSortingFunc("accountId")}
                              >
                                Account ID{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "accountId"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "accountId"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                              <th
                                onClick={() => handleSortingFunc("createdAt")}
                              >
                                {" "}
                                Order Date{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "createdAt"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "createdAt"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                              <th
                                onClick={() => handleSortingFunc("createdAt")}
                              >
                                {" "}
                                Cancel Date{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "createdAt"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "createdAt"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                              <th onClick={() => handleSortingFunc("symbol")}>
                                {" "}
                                Symbol{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "symbol"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "symbol"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                              <th
                                onClick={() => handleSortingFunc("stockPrice")}
                              >
                                {" "}
                                Stock Amount
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "stockPrice"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "stockPrice"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                              <th
                                onClick={() => handleSortingFunc("commission")}
                              >
                                Commission{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "commission"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "commission"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                              <th
                                onClick={() => handleSortingFunc("orderPrice")}
                              >
                                Order Price{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "orderPrice"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "orderPrice"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                              <th> Qty</th>
                              <th onClick={() => handleSortingFunc("status")}>
                                Status{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "status"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "status"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {closeTrades?.length > 0 &&
                              closeTrades?.map((list, i) => (
                                <tr
                                  key={i}
                                  onClick={() => handleCTradeShow(list)}
                                  style={{ cursor: "pointer" }}
                                  title="View"
                                >
                                  <td>
                                    {list?.userDetails?.first_name
                                      ? list?.userDetails?.first_name
                                      : "N/A"}
                                  </td>
                                  <td title="view" className="cursor-pointer">
                                    <b>
                                      {list?.userDetails?.accountData
                                        ?.account_number
                                        ? list?.userDetails?.accountData
                                            ?.account_number
                                        : "N/A"}
                                    </b>
                                  </td>
                                  <td title="view" className="cursor-pointer">
                                    <b>
                                      {list?.createdAt
                                        ? DATE_FORMAT_TIME_(list?.createdAt)
                                        : "N/A"}
                                    </b>
                                  </td>
                                  <td title="view" className="cursor-pointer">
                                    <b>
                                      {list?.createdAt
                                        ? DATE_FORMAT_TIME_(list?.canceledAt)
                                        : "N/A"}
                                    </b>
                                  </td>
                                  <td>{list.symbol ? list?.symbol : "N/A"}</td>
                                  <td>
                                    $
                                    {list?.requestAmount
                                      ? list?.requestAmount?.toFixed(2)
                                      : "0"}
                                  </td>
                                  <td className="green_status">
                                    $
                                    {list?.commission
                                      ? list?.commission?.toFixed(2)
                                      : "0"}
                                  </td>
                                  <td>
                                    $
                                    {list?.requestPrice
                                      ? list?.requestPrice?.toFixed(2)
                                      : "0"}
                                  </td>
                                  <td>{list?.qty ? list?.qty : "0"}</td>
                                  <td>{getStatus(list.status)}</td>
                                </tr>
                              ))}
                          </tbody>
                        </table>
                        {closeTrades?.length == 0 && (
                          <span className="py-2 spanclass">
                            No data found...
                          </span>
                        )}
                      </div>
                      {closeTrades?.length > 0 && (
                        <div className="pagination-box-review">
                          <ReactPagination
                            activePage={filter?.page}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={totalCountTrade}
                            handlePageChange={handlePageChangeTrade}
                          />
                        </div>
                      )}
                    </TabPanel>
                  </Tabs>
                </TabPanel>
                <TabPanel>
                  <Tabs
                    selectedIndex={tabIndex2}
                    onSelect={(index) => setTabIndex2(index)}
                  >
                    <TabList>
                      <Tab>Trading Activity</Tab>
                      <Tab>Non Trading Activity</Tab>
                    </TabList>
                    <TabPanel>
                      <div class="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> Account ID</th>
                              <th> Transaction Time</th>
                              <th> Type</th>
                              <th> Order Status</th>
                              <th>Price</th>
                              <th>Qty</th>
                              <th>Side</th>
                              {/* <th onClick={() => handleSortingFunc("type")}>
                                {" "}
                                Type{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "type"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "type"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                              <th
                                onClick={() =>
                                  handleSortingFunc("order_status")
                                }
                              >
                                {" "}
                                Order Status
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "order_status"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "order_status"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>{" "}
                              </th>
                              <th onClick={() => handleSortingFunc("price")}>
                                {" "}
                                Price
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "price"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "price"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th>
                              <th onClick={() => handleSortingFunc("qty")}>
                                {" "}
                                Qty{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "qty"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "qty"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th>
                              <th onClick={() => handleSortingFunc("side")}>
                                {" "}
                                Side{" "}
                                <i
                                  className={`cursor-pointer mdi mdi-arrow-${
                                    filter?.order === -1 &&
                                    filter?.orderBy === "side"
                                      ? "up"
                                      : "down"
                                  }`}
                                  title={
                                    filter?.order === -1 &&
                                    filter?.orderBy === "side"
                                      ? "Desc"
                                      : "Asc"
                                  }
                                ></i>
                              </th> */}
                              <th> Symbol </th>
                              <th> Order Id </th>
                            </tr>
                          </thead>
                          {currentItems?.length > 0 && (
                            <tbody>
                              {currentItems?.length > 0 &&
                                currentItems?.map((list, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {accountInfo ? accountInfo : "N/A"}
                                      </td>
                                      <td>
                                        {DATE_FORMAT_TIME(
                                          list.transaction_time
                                        )}
                                      </td>
                                      <td>{list.type}</td>
                                      <td>
                                        <label className="badge badge-success payment_status">
                                          {list.order_status}
                                        </label>
                                      </td>
                                      {/* <td>
                                        {list.price != null &&
                                        !isNaN(list.price)
                                          ? "$" +
                                            parseInt(`${list.price}`).toFixed(2)
                                          : "$0"}
                                      </td> */}
                                      <td>
                                        {"$" +
                                          (list.price
                                            ? Number(list.price).toFixed(2)
                                            : "0.00")}
                                      </td>

                                      <td>{list.qty}</td>
                                      <td>{list.side}</td>
                                      <td
                                        onClick={() =>
                                          renderTradesGraph(list?.symbol)
                                        }
                                        className="cursor-pointer"
                                        title="View"
                                      >
                                        <b>{list?.symbol}</b>
                                      </td>
                                      <td>{list.order_id}</td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          )}
                        </table>
                      </div>
                      {activities?.tradeActivity?.length > 0 && (
                        <div className="pagination-box-review">
                          <ReactPagination
                            activePage={activePage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={activities?.tradeActivity?.length}
                            handlePageChange={handlePageChange}
                          />
                        </div>
                      )}
                      {activities?.tradeActivity?.length == 0 && (
                        <span className="py-2 spanclass">No data found...</span>
                      )}
                    </TabPanel>
                    <TabPanel>
                      <div class="table-responsive">
                        <table className="table">
                          <thead>
                            <tr>
                              <th> Account ID</th>
                              <th> Activity Type </th>
                              <th> Date</th>
                              <th> Net Amount </th>
                              <th> Status </th>
                              <th> Description </th>
                            </tr>
                          </thead>
                          {currentNoneTradeItems?.length > 0 && (
                            <tbody>
                              {currentNoneTradeItems?.length > 0 &&
                                currentNoneTradeItems?.map((list, i) => {
                                  return (
                                    <tr key={i}>
                                      <td>
                                        {accountInfo ? accountInfo : "N/A"}
                                      </td>
                                      <td>{list.date}</td>
                                      <td>
                                        {DATE_FORMAT_TIME(
                                          list.transaction_time
                                        )}
                                      </td>
                                      <td>
                                        $
                                        {list.net_amount
                                          ? list.net_amount
                                          : "$0"}
                                      </td>
                                      <td>
                                        <label className="badge badge-success payment_status">
                                          {list.status}
                                        </label>
                                      </td>
                                      <td>
                                        {list.description
                                          ? list.description
                                          : "N/A"}
                                      </td>
                                    </tr>
                                  );
                                })}
                            </tbody>
                          )}
                        </table>
                      </div>
                      {activities?.nonTradeActivity?.length > 0 && (
                        <div className="pagination-box-review">
                          <ReactPagination
                            activePage={activePage}
                            itemsCountPerPage={itemsPerPage}
                            totalItemsCount={
                              activities?.nonTradeActivity?.length
                            }
                            handlePageChange={handlePageChange}
                          />
                        </div>
                      )}
                      {activities?.nonTradeActivity?.length == 0 && (
                        <span className="py-2 spanclass">No data found...</span>
                      )}
                    </TabPanel>
                  </Tabs>
                </TabPanel>
                <TabPanel>
                  <div class="table-responsive fixed_height">
                    <table className="table">
                      <thead>
                        <tr>
                          <th onClick={() => handleSortingFunc("orderPrice")}>
                            Client Id{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("orderPrice")}>
                            Client Name{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("orderPrice")}>
                            Registration date of that client{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("orderPrice")}>
                            Ballanced{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("orderPrice")}>
                            Paid Commission{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("orderPrice")}>
                            Pending Commission{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("orderPrice")}>
                            Total Commission{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                          <th onClick={() => handleSortingFunc("orderPrice")}>
                            Request Date{" "}
                            <i
                              className={`cursor-pointer mdi mdi-arrow-${
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "up"
                                  : "down"
                              }`}
                              title={
                                filter?.order === -1 &&
                                filter?.orderBy === "orderPrice"
                                  ? "Desc"
                                  : "Asc"
                              }
                            ></i>{" "}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {affiliateListing?.length > 0 &&
                          affiliateListing.map((list, i) => {
                            return (
                              <tr
                                onClick={() =>
                                  history.push(
                                    `/admin/tradeUser/listing/${list?.userId}/${editkey}`
                                  )
                                }
                                style={{ cursor: "pointer" }}
                                key={i}
                              >
                                <td>
                                  {
                                    list?.userDetails?.accountData
                                      ?.account_number
                                  }
                                </td>
                                <td>
                                  {
                                   list?.userDetails?.name ||   list?.userDetails?.first_name || ""
                                  }
                                </td>
                                <td>
                                  {list.userDetails?.createdAt
                                    ? DATE_FORMAT_TIME(
                                        list.userDetails?.createdAt
                                      )
                                    : "N/A"}
                                </td>
                                <td>
                                  {" "}
                                  ${" "}
                                  {list?.userDetails?.walletCredit
                                    ? list?.userDetails?.walletCredit
                                    : "N/A"}
                                </td>
                                <td>
                                  ${" "}
                                  {list?.approvedCommission &&
                                  list?.approvedCommission > 0
                                    ? `${parseFloat(list?.approvedCommission)}`
                                    : "0"}
                                </td>
                                <td>
                                  {" "}
                                  ${" "}
                                  {list?.pendingCommission &&
                                  list?.pendingCommission > 0
                                    ? `${parseFloat(list?.pendingCommission)}`
                                    : "0"}
                                </td>
                                <td>
                                  {" "}
                                  ${" "}
                                  {list?.totalCommission &&
                                  list?.totalCommission > 0
                                    ? `${parseFloat(list?.totalCommission)}`
                                    : "0"}
                                </td>
                                <td>
                                  {list?.createdAt
                                    ? DATE_FORMAT_TIME(list?.createdAt)
                                    : "N/A"}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                  {affiliateListing?.length > 0 && (
                    <div className="pagination-box-review">
                      <ReactPagination
                        activePage={activePage}
                        itemsCountPerPage={itemsCountPerPage}
                        totalItemsCount={affiliateCount}
                        handlePageChange={handlePageChange}
                      />
                    </div>
                  )}
                  {affiliateListing?.length == 0 && (
                    <div className="row">
                      <div className="col-md-6 pt-5">
                        <span>No data found...</span>
                      </div>
                    </div>
                  )}
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>

        <div className="col-lg-4 grid-margin">
          <div className="card graph_card border_custom">
            <div className="card-body">
              <div className="d-flex align-items-center justify-content-between">
                <h5> Documents </h5>
                <div className="p-0 d-flex align-items-center justify-content-end">
                  {editData?.documents ? (
                    <label class="badge badge-success">Passed</label>
                  ) : (
                    <button
                      type="submit"
                      onClick={() =>
                        userRole?.role === "SUBADMIN" &&
                        userPermissions?.hasOwnProperty("userCount") &&
                        userPermissions?.userCount?.update
                          ? VerifyStatusFunc(true, "documents")
                          : userRole?.role === "ADMIN"
                          ? VerifyStatusFunc(true, "documents")
                          : console.log("#")
                      }
                      className="btn btn-sm btn-gradient-primary"
                      title="Incomplete"
                    >
                      Incomplete
                    </button>
                  )}
                </div>
              </div>

              <div className="d-flex align-items-center justify-content-between">
                <div className="p-0 d-flex align-items-center justify-content-end">
                  <label className=" col-form-label">
                    <a href={editData?.id_photo} target="_blank">
                      Passport
                    </a>
                  </label>
                </div>
                <div className="col-md-2">
                  <button
                    type="button"
                    className="edit_card_cta btn btn-sm btn-gradient-primary"
                    title="Edit"
                    onClick={
                      userRole?.role === "SUBADMIN" &&
                      userPermissions?.hasOwnProperty("userCount") &&
                      userPermissions?.userCount?.update
                        ? handleDocumentsShow
                        : userRole?.role === "ADMIN"
                        ? handleDocumentsShow
                        : console.log("#")
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_252_1049)">
                        <path
                          d="M15.2933 0.706709C14.8401 0.254173 14.2258 0 13.5853 0C12.9449 0 12.3306 0.254173 11.8773 0.706709L0 12.584V16H3.416L15.2933 4.12271C15.7457 3.66939 15.9998 3.05513 15.9998 2.41471C15.9998 1.77429 15.7457 1.16003 15.2933 0.706709ZM2.86667 14.6667H1.33333V13.1334L10.2067 4.26671L11.74 5.80004L2.86667 14.6667ZM14.3507 3.18004L12.6793 4.85138L11.1493 3.31804L12.82 1.64938C13.0233 1.44604 13.2991 1.33181 13.5867 1.33181C13.8742 1.33181 14.15 1.44604 14.3533 1.64938C14.5567 1.85271 14.6709 2.12849 14.6709 2.41604C14.6709 2.7036 14.5567 2.97938 14.3533 3.18271L14.3507 3.18004Z"
                          fill="black"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_252_1049">
                          <rect width="16" height="16" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">ID Type</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.id_type ? `${editData?.id_type}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">City</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.city ? `${editData?.city}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">Unit</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.unit ? `${editData?.unit}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">Country</label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.country ? `${editData?.country}` : "N/A"}
                  </label>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    Address Information
                  </label>
                </div>
                <div className="col-md-6">
                  <label className="col-sm-12 col-form-label">
                    {editData?.address_information
                      ? `${editData?.address_information}`
                      : "N/A"}
                  </label>
                </div>
              </div>
              <hr />
              <div className="row">
                <div className="grid-margin stretch-card">
                  <div className="card">
                    <div className="">
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>Biometric</h5>
                        <div className="p-0 d-flex align-items-center justify-content-end">
                          {editData?.biometric ? (
                            <label class="badge badge-success">Passed</label>
                          ) : (
                            <button
                              type="submit"
                              onClick={() =>
                                userRole?.role === "SUBADMIN" &&
                                userPermissions.hasOwnProperty("userCount") &&
                                userPermissions?.userCount.hasOwnProperty(
                                  "update"
                                )
                                  ? VerifyStatusFunc(true, "biometric")
                                  : userRole?.role === "ADMIN"
                                  ? VerifyStatusFunc(true, "biometric")
                                  : console.log("#")
                              }
                              className="btn btn-sm btn-gradient-primary"
                              title="Incomplete"
                            >
                              Incomplete
                            </button>
                          )}
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-center justify-content-between">
                        <h5>Employment</h5>
                        <div className="p-0 d-flex align-items-center justify-content-end">
                          {editData?.employment ? (
                            <label class="badge badge-success">Passed</label>
                          ) : (
                            <button
                              type="submit"
                              onClick={() =>
                                userRole?.role === "SUBADMIN" &&
                                userPermissions.hasOwnProperty("userCount") &&
                                userPermissions?.userCount.hasOwnProperty(
                                  "update"
                                )
                                  ? VerifyStatusFunc(true, "employment")
                                  : userRole?.role === "ADMIN"
                                  ? VerifyStatusFunc(true, "employment")
                                  : console.log("#")
                              }
                              className="btn btn-sm btn-gradient-primary"
                              title="Incomplete"
                            >
                              Incomplete
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Activities
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.activities
                              ? `${editData?.activities}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Employed
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.employed
                              ? `${editData?.employed}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Job Title
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.job_title
                              ? `${editData?.job_title}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Company's Name
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.company_name
                              ? `${editData?.company_name}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Company's Address
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.company_address
                              ? `${editData?.company_address}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Origin of Money
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.origin_of_money
                              ? `${editData?.origin_of_money}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            Liquid Assets
                          </label>
                        </div>
                        <div className="col-md-6">
                          <label className="col-sm-12 col-form-label">
                            {editData?.liquid_assets
                              ? `${editData?.liquid_assets}`
                              : "N/A"}
                          </label>
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="col-md-9">
                          <h5>Is Trade</h5>
                        </div>
                        <div className="p-0 d-flex align-items-center justify-content-end">
                          <Toggle
                            className="form-control"
                            id="cheese-status"
                            checked={editData?.isTrade}
                            name="isTrade"
                            onChange={() =>
                              userRole?.role === "SUBADMIN" &&
                              userPermissions.hasOwnProperty("userCount") &&
                              userPermissions?.userCount?.update
                                ? VerifyStatusTradingFunc("isTrade")
                                : userRole?.role === "ADMIN"
                                ? VerifyStatusTradingFunc("isTrade")
                                : console.log("#")
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="col-md-9">
                          <h5>Is Deposit</h5>
                        </div>
                        <div className="p-0 d-flex align-items-center justify-content-end">
                          <Toggle
                            className="form-control"
                            id="cheese-status"
                            name="isDeposit"
                            checked={editData?.isDeposit}
                            onChange={() =>
                              userRole?.role === "SUBADMIN" &&
                              userPermissions.hasOwnProperty("userCount") &&
                              userPermissions?.userCount?.update
                                ? VerifyStatusTradingFunc("isDeposit")
                                : userRole?.role === "ADMIN"
                                ? VerifyStatusTradingFunc("isDeposit")
                                : console.log("#")
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="col-md-9">
                          <h5>Is Withdrawal</h5>
                        </div>
                        <div className="p-0 d-flex align-items-center justify-content-end">
                          <Toggle
                            className="form-control"
                            id="cheese-status"
                            name="isWithdraw"
                            checked={editData?.isWithdraw}
                            onChange={() =>
                              userRole?.role === "SUBADMIN" &&
                              userPermissions.hasOwnProperty("userCount") &&
                              userPermissions?.userCount?.update
                                ? VerifyStatusTradingFunc("isWithdraw")
                                : userRole?.role === "ADMIN"
                                ? VerifyStatusTradingFunc("isWithdraw")
                                : console.log("#")
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="col-md-9">
                          <h5>Closed Only</h5>
                        </div>
                        <div className="p-0 d-flex align-items-center justify-content-end">
                          <Toggle
                            className="form-control"
                            id="cheese-status"
                            name="isClosed"
                            checked={editData?.isClosed}
                            onChange={() =>
                              userRole?.role === "SUBADMIN" &&
                              userPermissions.hasOwnProperty("userCount") &&
                              userPermissions?.userCount?.update
                                ? VerifyStatusTradingFunc("isClosed")
                                : userRole?.role === "ADMIN"
                                ? VerifyStatusTradingFunc("isClosed")
                                : console.log("#")
                            }
                          />
                        </div>
                      </div>
                      <hr />
                      <Commission
                        setisUpdateProfile={setisUpdateProfile}
                        editData={editData}
                        dispatch={dispatch}
                        userPermissions={userPermissions}
                        userRole={userRole}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <BackofficeComment
          dispatch={dispatch}
          editkey={editkey}
          setisUpdateProfile={setisUpdateProfile}
          editData={editData}
        />
        {/* {editData?.cipInfo !== null && ( */}
        <OrderDataInfo
          setisUpdateProfile={setisUpdateProfile}
          editData={editData}
          dispatch={dispatch}
        />
        {/* )} */}
      </div>

      <SendMessageModal
        show={show}
        handleClose={handleClose}
        handleShow={handleShow}
        dispatch={dispatch}
        setShow={setShow}
        editData={editData}
        setisUpdateProfile={setisUpdateProfile}
      />
      <AddressModal
        showAddress={showAddress}
        handleAddressClose={handleAddressClose}
        handleAddressShow={handleAddressShow}
        editData={editData}
        dispatch={dispatch}
        setShowAddress={setShowAddress}
        setisUpdateProfile={setisUpdateProfile}
      />
      <DocumentsModal
        showDocuments={showDocuments}
        handleDocumentsClose={handleDocumentsClose}
        handleDocumentsShow={handleDocumentsShow}
        editData={editData}
        dispatch={dispatch}
        setisUpdateProfile={setisUpdateProfile}
        setShowDocuments={setShowDocuments}
      />
      <ProfileEdit
        editProfileModal={editProfileModal}
        handleEditProfileClose={handleEditProfileClose}
        handleEditProfileShow={handleEditProfileShow}
        editData={editData}
        dispatch={dispatch}
        setEditProfileModal={setEditProfileModal}
        setisUpdateProfile={setisUpdateProfile}
      />
      <AssignMembers
        assignMember={assignMember}
        handleAssignClose={handleAssignClose}
        handleAddressShow={handleAddressShow}
        dispatch={dispatch}
        setAssignMember={setAssignMember}
        editData={editData}
        setisUpdateProfile={setisUpdateProfile}
      />
      <BalanceOperation
        operation={operation}
        handleOperationClose={handleOperationClose}
        handleOperationShow={handleOperationShow}
        dispatch={dispatch}
        setOperation={setOperation}
        editData={editData}
        setisUpdateProfile={setisUpdateProfile}
      />
      <InfoModal
        showInfoModal={showInfoModal}
        handleInfoClose={handleInfoClose}
        // handleInfoShow={handleInfoShow}
        currentInfoValue={currentInfoValue}
        setCurrentInfoValue={setCurrentInfoValue}
      />
      <TradeModalInfo
        showTradeModal={showTradeModal}
        handleTradeClose={handleTradeClose}
        // handleTradeShow={handleTradeShow}
        currentTradeValue={currentTradeValue}
        setCurrentTradeValue={setCurrentTradeValue}
      />
      <TradeModalInfoClose
        showCTradeModal={showCTradeModal}
        handleCTradeClose={handleCTradeClose}
        // handleTradeShow={handleTradeShow}
        currentCloseTradeValue={currentCloseTradeValue}
        setCurrentCloseTradeValue={setCurrentCloseTradeValue}
      />
      <ImagePreviewModal
        selectedImage={selectedImage}
        setSelectedImage={setSelectedImage}
      />
    </>
  );
};

const mapStateToPros = (state) => {
  return {
    itemsCountPerPage: state.Common.itemsCountPerPage,
    totalItemsCount: state.Common.totalItemsCount,
    loader: state.Common.loader,
    user: state.Auth.user,
    userProfile: state?.persistStore?.userProfile,
  };
};
function mapDispatchToProps(dispatch) {
  return { dispatch };
}

export default connect(mapStateToPros, mapDispatchToProps)(Listing);
