import { DATE_FORMAT_TIME } from "helpers/common";
import React, { useState } from "react";
import ShowAffielteDataModal from "./Modals/ShowAffielteDataModal";

const RenderListAffiliate = ({ affilatesData }) => {
  const [showModal, setShowModal] = useState({
    show: false,
    data: null,
  });

  return (
    <>
      <ShowAffielteDataModal model={showModal} setModel={setShowModal} />

      {affilatesData?.length > 0 &&
        affilatesData.map((list, i) => {
          return (
            <tr
              key={i}
              style={{ cursor: "pointer" }}
              onClick={() => {
                setShowModal((p) => ({
                  show: true,
                  data: list,
                }));
              }}
            >
              <td>
                {list?.userDetails?.affiliatedCode
                  ? list?.userDetails?.affiliatedCode
                  : "N/A"}
              </td>
              <td>
                {list.userDetails?.accountData?.account_number
                  ? list.userDetails?.accountData?.account_number
                  : "N/A"}
              </td>
              <td>
                {list.userDetails?.name ||
                  list.userDetails?.first_name ||
                  "N/A"}
              </td>
              <td>
                {list?.createdAt ? DATE_FORMAT_TIME(list?.createdAt) : "N/A"}
              </td>
              <td>
                {" "}
                {list.userDetails?.affiliatedUserCount
                  ? list.userDetails?.affiliatedUserCount
                  : 0}
              </td>
              <td>
                {" "}
                {list?.tradeCount
                  ? list?.tradeCount
                  : 0}
              </td>
              <td>
                ${" "}
                {list?.totalCommission && list?.totalCommission > 0
                  ? `${parseFloat(list?.totalCommission)}`
                  : "0"}
              </td>
              <td>
                {" "}
                ${" "}
                {list?.approvedCommission && list?.approvedCommission > 0
                  ? `${parseFloat(list?.approvedCommission).toFixed(2)}`
                  : "0"}
              </td>
              <td>
                {" "}
                ${" "}
                {list?.pendingCommission && list?.pendingCommission > 0
                  ? `${parseFloat(list?.pendingCommission).toFixed(2)}`
                  : "0"}
              </td>
              <td>
                {" "}
                  {list?.lastTradeDate
                    ? DATE_FORMAT_TIME(list?.lastTradeDate)
                    : "N/A"}
              </td>
            </tr>
          );
        })}
    </>
  );
};

export default RenderListAffiliate;
