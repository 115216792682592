import React, { lazy } from "react";

//imported
import * as Path from "./paths";
//auth
import SignIn from "components/views/admin/auth/Login";
//forgot password
import ForgotPassword from "components/views/admin/auth/ForgotPassword";
import ResetPassword from "components/views/admin/auth/ResetPassword";

//profile
import Profile from "components/views/admin/profile/Profile";
//ChangePassword
import ChangePassword from "components/views/admin/profile/ChangePassword";

//dashboard
import Dashboard from "components/views/admin/dashboard/Dashboard";
//users
import UserList from "components/views/admin/user/Listing";
import UserDetail from "components/views/admin/user/Detail";
import UserAddEdit from "components/views/admin/user/AddEdit";

//jfx
import JFXUserList from "components/views/admin/jfx/Listing";
import JFXUserDetail from "components/views/admin/jfx/Detail";

//kliring
import KliringUserList from "components/views/admin/kliring/Listing";
import KliringUserDetail from "components/views/admin/kliring/Detail";
//payment
import PaymentList from "components/views/admin/payments/Listing";
import PaymentDetail from "components/views/admin/payments/Detail";
import PaymentAddEdit from "components/views/admin/payments/AddEdit";
//promotion
import PromotionList from "components/views/admin/promotions/Listing";
import PromotionDetail from "components/views/admin/promotions/Detail";
import PromotionAddEdit from "components/views/admin/promotions/AddEdit";
//trades
import TradesList from "components/views/admin/trades/Listing";
import UsertradesList from "components/views/admin/tradesUser/Listing";

import TradesDetail from "components/views/admin/trades/Detail";
import ScheduleOrder from "components/views/admin/trades/ScheduleHistory";
import TradesAddEdit from "components/views/admin/trades/AddEdit";
//system access
import SystemAccessList from "components/views/admin/system_access/Listing";
import SystemAccessAddEdit from "components/views/admin/system_access/AddEdit";
import SystemAccessDetail from "components/views/admin/system_access/Detail";
//cms
import CMSList from "components/views/admin/cms/Listing";
import CMSAddEdit from "components/views/admin/cms/AddEdit";
import CmsContent from "components/publicCMS/CmsContent";
//FAQ
import FAQList from "components/views/admin/faq/Listing";
import FAQAddEdit from "components/views/admin/faq/AddEdit";
//Contact support
import ContactSupport from "components/views/admin/contact_support/Listing";
//Affiliate Referral
import AffiliateReferralList from "components/views/admin/affiliates_referrals/Listing";
import AffiliateReferralView from "components/views/admin/affiliates_referrals/Detail";
//Report
import ReportList from "components/views/admin/reports/Listing";
import ReportListView from "components/views/admin/reports/Detail";
//Revenue
import RevenueList from "components/views/admin/revenue/Listing";
import RevenueListView from "components/views/admin/revenue/Detail";

// Blogs
import BlogsList from "components/views/admin/blogs/Listing";
import BlogsAddEdit from "components/views/admin/blogs/AddEdit";
import BlogsView from "components/views/admin/blogs/Detail";

// Jobs
import JobsList from "components/views/admin/jobs/Listing";
import JobsAddEdit from "components/views/admin/jobs/AddEdit";
// import JobsView from "components/views/admin/jobs/Detail";

// Testimony
import TestimonyList from "components/views/admin/testimony/Listing";
import TestimonyAddEdit from "components/views/admin/testimony/AddEdit";

// Feature
import FeatureList from "components/views/admin/feature/Listing";
import FeatureAddEdit from "components/views/admin/feature/AddEdit";

// Basic setting
import BasicSetting from "components/views/admin/basicSetting/AddEdit";

//Configuration
import ConfigurationAddEdit from "components/views/admin/configuration/AddEdit";
import ConfigurationList from "components/views/admin/configuration/Listing";
//BackOffice
import BackOfficeList from "components/views/admin/backoffice/Listing";
import BackOfficeView from "components/views/admin/backoffice/Detail";

//ManageLogs
import ManageLogsList from "components/views/admin/manage_logs/Listing";
import ManageLogsView from "components/views/admin/manage_logs/Detail";

//ManageStock
import ManageStockList from "components/views/admin/manage_stocks/Listing";
// import ManageLogsView from "components/views/admin/manage_stock/Detail";
//Bank Request
import BankRequestList from "components/views/admin/bank_request/Listing";

//not found page
import NotFound from "components/NotFound";

const actionEnum = {
  view: "view",
  create: "create",
  update: "update",
  delete: "delete",
  cip_info_edit: "cip_info_edit",
  cip_info_view: "cip_info_view",
  balance_operation_pay: "balance_operation_pay",
  corporate_withdrawal: "corporate_withdrawal",
};

const moduleKeys = {
  dashboard: "dashboard",
  promotions: "promotions",
  userCount: "userCount",
  transactionCount: "transactionCount",
  tradeCount: "tradeCount",
  revenue: "revenue",
  report: "report",
  affiliates: "affiliates",
  bank_request: "bank_request",
  system_access: "system_access",
  manage_logs: "manage_logs",
  backoffice: "backoffice",
  cms: "cms",
  jfxPage: "jfxPage",
  configuration: "configuration",
  landingPage: "landingPage",
  kliringPage: "kliringPage",
};

const routes = [
  /******** login routes ***********/
  {
    path: Path.login,
    exact: true,
    auth: false,
    fallback: true,
    // component: lazy(() => import('components/views/admin/modules/auth/Login'))
    component: SignIn,
  },
  /******** forgot password routes ***********/
  {
    path: Path.forgot_password,
    exact: true,
    auth: false,
    component: ForgotPassword,
  },
  /******** reset password ***********/
  {
    path: Path.reset_password,
    exact: true,
    auth: false,
    component: ResetPassword,
  },
  /******** dashboard ***********/
  {
    path: Path.dashboard,
    exact: true,
    auth: true,
    component: Dashboard,
    isPermitted: {
      key: moduleKeys?.dashboard,
      action: actionEnum?.view,
    },
  },
  /******** users***********/
  {
    path: Path.users,
    exact: true,
    auth: true,
    component: UserList,
    isPermitted: {
      key: moduleKeys?.userCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.user_add,
    exact: true,
    auth: true,
    component: UserAddEdit,
    isPermitted: {
      key: moduleKeys?.userCount,
      action: actionEnum?.update,
    },
  },
  {
    path: Path.user_edit,
    exact: true,
    auth: true,
    component: UserAddEdit,
    isPermitted: {
      key: moduleKeys?.userCount,
      action: actionEnum?.update,
    },
  },
  {
    path: Path.useredit,
    exact: true,
    auth: true,
    component: UserAddEdit,
    isPermitted: {
      key: moduleKeys?.userCount,
      action: actionEnum?.update,
    },
  },
  {
    path: Path.user_view,
    exact: true,
    auth: true,
    component: UserDetail,
    isPermitted: {
      key: moduleKeys?.userCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.userview,
    exact: true,
    auth: true,
    component: UserDetail,
    isPermitted: {
      key: moduleKeys?.userCount,
      action: actionEnum?.view,
    },
  },
  /******** payment***********/
  {
    path: Path.payment,
    exact: true,
    auth: true,
    component: PaymentList,
    isPermitted: {
      key: moduleKeys?.transactionCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.payment_add,
    exact: true,
    auth: true,
    component: PaymentAddEdit,
    isPermitted: {
      key: moduleKeys?.transactionCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.payment_edit,
    exact: true,
    auth: true,
    component: PaymentAddEdit,
    isPermitted: {
      key: moduleKeys?.transactionCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.paymentedit,
    exact: true,
    auth: true,
    component: PaymentAddEdit,
    isPermitted: {
      key: moduleKeys?.transactionCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.payment_view,
    exact: true,
    auth: true,
    component: PaymentDetail,
    isPermitted: {
      key: moduleKeys?.transactionCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.paymentview,
    exact: true,
    auth: true,
    component: PaymentDetail,
    isPermitted: {
      key: moduleKeys?.transactionCount,
      action: actionEnum?.view,
    },
  },
  /******** promotion ***********/
  {
    path: Path.promotion,
    exact: true,
    auth: true,
    component: PromotionList,
    isPermitted: {
      key: moduleKeys?.promotions,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.promotion_add,
    exact: true,
    auth: true,
    component: PromotionAddEdit,
    isPermitted: {
      key: moduleKeys?.promotions,
      action: actionEnum?.create,
    },
  },
  {
    path: Path.promotion_edit,
    exact: true,
    auth: true,
    component: PromotionAddEdit,
    isPermitted: {
      key: moduleKeys?.promotions,
      action: actionEnum?.update,
    },
  },
  {
    path: Path.promotionedit,
    exact: true,
    auth: true,
    component: PromotionAddEdit,
    isPermitted: {
      key: moduleKeys?.promotions,
      action: actionEnum?.update,
    },
  },
  {
    path: Path.promotionview,
    exact: true,
    auth: true,
    component: PromotionDetail,
    isPermitted: {
      key: moduleKeys?.promotions,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.promotion_view,
    exact: true,
    auth: true,
    component: PromotionDetail,
    isPermitted: {
      key: moduleKeys?.promotions,
      action: actionEnum?.view,
    },
  },
  /******** Trades ***********/
  {
    path: Path.tradeuser,
    exact: true,
    auth: true,
    component: UsertradesList,
    isPermitted: {
      key: moduleKeys?.tradeCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.trade,
    exact: true,
    auth: true,
    component: TradesList,
    isPermitted: {
      key: moduleKeys?.tradeCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.trade_add,
    exact: true,
    auth: true,
    component: TradesAddEdit,
    isPermitted: {
      key: moduleKeys?.tradeCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.trade_edit,
    exact: true,
    auth: true,
    component: TradesAddEdit,
    isPermitted: {
      key: moduleKeys?.tradeCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.tradeedit,
    exact: true,
    auth: true,
    component: TradesAddEdit,
    isPermitted: {
      key: moduleKeys?.tradeCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.tradeview,
    exact: true,
    auth: true,
    component: TradesDetail,
    isPermitted: {
      key: moduleKeys?.tradeCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.trade_view,
    exact: true,
    auth: true,
    component: TradesDetail,
    isPermitted: {
      key: moduleKeys?.tradeCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.schedule_order,
    exact: true,
    auth: true,
    component: ScheduleOrder,
    isPermitted: {
      key: moduleKeys?.tradeCount,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.schedule_orders,
    exact: true,
    auth: true,
    component: ScheduleOrder,
    isPermitted: {
      key: moduleKeys?.tradeCount,
      action: actionEnum?.view,
    },
  },
  /******** system access ***********/
  {
    path: Path.system_access,
    exact: true,
    auth: true,
    component: SystemAccessList,
    isPermitted: {
      key: moduleKeys?.system_access,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.system_access_add,
    exact: true,
    auth: true,
    component: SystemAccessAddEdit,
    isPermitted: {
      key: moduleKeys?.system_access,
      action: actionEnum?.create,
    },
  },
  {
    path: Path.system_access_edit,
    exact: true,
    auth: true,
    component: SystemAccessAddEdit,
    isPermitted: {
      key: moduleKeys?.system_access,
      action: actionEnum?.update,
    },
  },
  {
    path: Path.system_accessedit,
    exact: true,
    auth: true,
    component: SystemAccessAddEdit,
    isPermitted: {
      key: moduleKeys?.system_access,
      action: actionEnum?.update,
    },
  },
  {
    path: Path.system_accessview,
    exact: true,
    auth: true,
    component: SystemAccessDetail,
    isPermitted: {
      key: moduleKeys?.system_access,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.system_access_view,
    exact: true,
    auth: true,
    component: SystemAccessDetail,
    isPermitted: {
      key: moduleKeys?.system_access,
      action: actionEnum?.view,
    },
  },
  /**************Profile**********/
  {
    path: Path.profile,
    exact: true,
    auth: true,
    component: Profile,
    everyOneCanAccess: true,
  },
  /**************Change password**********/
  {
    path: Path.change_password,
    exact: true,
    auth: true,
    component: ChangePassword,
    everyOneCanAccess: true,
  },
  /******** cms ***********/
  {
    path: Path.cms,
    exact: true,
    auth: true,
    component: CMSList,
    isPermitted: {
      key: moduleKeys?.cms,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.cms_form,
    exact: true,
    auth: true,
    component: CMSAddEdit,
    isPermitted: {
      key: moduleKeys?.cms,
      action: actionEnum?.create,
    },
  },
  {
    path: Path.cmsform,
    exact: true,
    auth: true,
    component: CMSAddEdit,
    isPermitted: {
      key: moduleKeys?.cms,
      action: actionEnum?.create,
    },
  },
  {
    path: Path.cmsPage,
    exact: true,
    auth: false,
    component: CmsContent,
    isPermitted: {
      key: moduleKeys?.cms,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.cms_Page,
    exact: true,
    auth: false,
    component: CmsContent,
    isPermitted: {
      key: moduleKeys?.cms,
      action: actionEnum?.view,
    },
  },
  /********** jfx **********/
  {
    path: Path.jfxusers,
    exact: true,
    auth: true,
    component: JFXUserList,
    isPermitted: {
      key: moduleKeys?.jfxPage,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.jfxuser_view,
    exact: true,
    auth: true,
    component: JFXUserDetail,
    isPermitted: {
      key: moduleKeys?.jfxPage,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.jfxuserview,
    exact: true,
    auth: true,
    component: JFXUserDetail,
    isPermitted: {
      key: moduleKeys?.jfxPage,
      action: actionEnum?.view,
    },
  },

  /********** kliring **********/
  {
    path: Path.kliringusers,
    exact: true,
    auth: true,
    component: KliringUserList,
    isPermitted: {
      key: moduleKeys?.kliringPage,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.kliringuser_view,
    exact: true,
    auth: true,
    component: KliringUserDetail,
    isPermitted: {
      key: moduleKeys?.kliringPage,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.kliringuserview,
    exact: true,
    auth: true,
    component: KliringUserDetail,
    isPermitted: {
      key: moduleKeys?.kliringPage,
      action: actionEnum?.view,
    },
  },

  /******** faq ***********/
  {
    path: Path.faq,
    exact: true,
    auth: true,
    component: FAQList,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.faq_form,
    exact: true,
    auth: true,
    component: FAQAddEdit,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.create,
    },
  },
  {
    path: Path.faqform,
    exact: true,
    auth: true,
    component: FAQAddEdit,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.update,
    },
  },
  /******** affiliates & referrals ***********/
  {
    path: Path.affiliates,
    exact: true,
    auth: true,
    component: AffiliateReferralList,
    isPermitted: {
      key: moduleKeys?.affiliates,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.affiliates_view,
    exact: true,
    auth: true,
    component: AffiliateReferralView,
    isPermitted: {
      key: moduleKeys?.affiliates,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.affiliatesview,
    exact: true,
    auth: true,
    component: AffiliateReferralView,
    isPermitted: {
      key: moduleKeys?.affiliates,
      action: actionEnum?.view,
    },
  },
  /******** revenue ***********/
  {
    path: Path.revenue,
    exact: true,
    auth: true,
    component: RevenueList,
    isPermitted: {
      key: moduleKeys?.revenue,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.revenue_view,
    exact: true,
    auth: true,
    component: RevenueListView,
    isPermitted: {
      key: moduleKeys?.revenue,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.revenueview,
    exact: true,
    auth: true,
    component: RevenueListView,
    isPermitted: {
      key: moduleKeys?.revenue,
      action: actionEnum?.view,
    },
  },
  /******** blogs ***********/
  {
    path: Path.blogs,
    exact: true,
    auth: true,
    component: BlogsList,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.blogs_add,
    exact: true,
    auth: true,
    component: BlogsAddEdit,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.create,
    },
  },
  {
    path: Path.blogsedit,
    exact: true,
    auth: true,
    component: BlogsAddEdit,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.update,
    },
  },
  {
    path: Path.blogs_view,
    exact: true,
    auth: true,
    component: BlogsView,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.update,
    },
  },

  /******** jobs ***********/
  {
    path: Path.jobs,
    exact: true,
    auth: true,
    component: JobsList,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.jobs_add,
    exact: true,
    auth: true,
    component: JobsAddEdit,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.create,
    },
  },
  {
    path: Path.jobsedit,
    exact: true,
    auth: true,
    component: JobsAddEdit,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.update,
    },
  },
  // {
  //   path: Path.jobs_view,
  //   exact: true,
  //   auth: true,
  //   component: JobsView,
  // },

  /******** testimony ***********/
  {
    path: Path.testimony,
    exact: true,
    auth: true,
    component: TestimonyList,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.testimony_add,
    exact: true,
    auth: true,
    component: TestimonyAddEdit,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.create,
    },
  },
  {
    path: Path.testimonyedit,
    exact: true,
    auth: true,
    component: TestimonyAddEdit,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.update,
    },
  },

  /******** feature ***********/
  {
    path: Path.feature,
    exact: true,
    auth: true,
    component: FeatureList,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.feature_add,
    exact: true,
    auth: true,
    component: FeatureAddEdit,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.create,
    },
  },
  {
    path: Path.featureedit,
    exact: true,
    auth: true,
    component: FeatureAddEdit,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.update,
    },
  },

  /******** basic Setting ***********/
  {
    path: Path.basicSetting,
    exact: true,
    auth: true,
    component: BasicSetting,
    isPermitted: {
      key: moduleKeys?.landingPage,
      action: actionEnum?.update,
    },
  },

  /******** report ***********/
  {
    path: Path.report,
    exact: true,
    auth: true,
    component: ReportList,
    isPermitted: {
      key: moduleKeys?.report,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.report_view,
    exact: true,
    auth: true,
    component: ReportListView,
    isPermitted: {
      key: moduleKeys?.report,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.reportview,
    exact: true,
    auth: true,
    component: ReportListView,
    isPermitted: {
      key: moduleKeys?.report,
      action: actionEnum?.view,
    },
  },
  /******** backoffice ***********/
  {
    path: Path.backoffice,
    exact: true,
    auth: true,
    component: BackOfficeList,
    isPermitted: {
      key: moduleKeys?.backoffice,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.backoffice_view,
    exact: true,
    auth: true,
    component: BackOfficeView,
    isPermitted: {
      key: moduleKeys?.backoffice,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.backofficeview,
    exact: true,
    auth: true,
    component: BackOfficeView,
    isPermitted: {
      key: moduleKeys?.backoffice,
      action: actionEnum?.view,
    },
  },
  /******** manageLogs ***********/
  {
    path: Path.manage_logs,
    exact: true,
    auth: true,
    component: ManageLogsList,
    isPermitted: {
      key: moduleKeys?.manage_logs,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.manage_logs_view,
    exact: true,
    auth: true,
    component: ManageLogsView,
    isPermitted: {
      key: moduleKeys?.manage_logs,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.manage_logsview,
    exact: true,
    auth: true,
    component: ManageLogsView,
    isPermitted: {
      key: moduleKeys?.manage_logs,
      action: actionEnum?.view,
    },
  },
  /******** manageStocks ***********/
  {
    path: Path.manage_stock,
    exact: true,
    auth: true,
    component: ManageStockList,
  },
  /******** bank request ***********/
  {
    path: Path.bank_request,
    exact: true,
    auth: true,
    component: BankRequestList,
    isPermitted: {
      key: moduleKeys?.bank_request,
      action: actionEnum?.view,
    },
  },
  /******** Configuration ***********/
  {
    path: Path.configuration,
    exact: true,
    auth: true,
    component: ConfigurationList,
    isPermitted: {
      key: moduleKeys?.configuration,
      action: actionEnum?.view,
    },
  },
  {
    path: Path.configuration_add,
    exact: true,
    auth: true,
    component: ConfigurationAddEdit,
    isPermitted: {
      key: moduleKeys?.configuration,
      action: actionEnum?.create,
    },
  },
  {
    path: Path.configurationedit,
    exact: true,
    auth: true,
    component: ConfigurationAddEdit,
    isPermitted: {
      key: moduleKeys?.configuration,
      action: actionEnum?.update,
    },
  },
  {
    path: Path.configuration_edit,
    exact: true,
    auth: true,
    component: ConfigurationAddEdit,
    isPermitted: {
      key: moduleKeys?.configuration,
      action: actionEnum?.update,
    },
  },

  /**************Contact support**********/
  {
    path: Path.contact_support,
    exact: true,
    auth: true,
    component: ContactSupport,
    everyOneCanAccess: true,
  },

  /******** not found page ***********/
  {
    path: "",
    exact: false,
    component: NotFound,
    everyOneCanAccess: true,
  },
];

export default routes;
