let mainPath = "/admin";
//auth
export const login = "/admin/login";
export const forgot_password = "/admin/forgot_password";
export const reset_password = "/admin/reset_password/:token";

//auth
export const dashboard = `${mainPath}/dashboard`;

//profile
export const profile = `${mainPath}/profile`;
export const change_password = `${mainPath}/change_password`;

//contact_support
export const contact_support = `${mainPath}/contact_support`;

//transaction
export const transaction = `${mainPath}/transaction/listing`;
export const transaction_view = `${mainPath}/transaction/detail`;
//cms
export const cms = `${mainPath}/content_page`;
export const cms_form = `${mainPath}/content_page/form`;
export const cmsform = `${mainPath}/content_page/form/:key`;
export const cms_Page = "/cms";
export const cmsPage = `/cms/:key`;
//FAQ
export const faq = `${mainPath}/faq`;
export const faq_form = `${mainPath}/faq/form`;
export const faqform = `${mainPath}/faq/form/:id`;

//system_access
export const system_access = `${mainPath}/system_access/listing`;
export const system_access_view = `${mainPath}/system_access/detail/:id`;
export const system_access_add = `${mainPath}/system_access/add`;
export const system_access_edit = `${mainPath}/system_access/edit`;
export const system_accessedit = `${mainPath}/system_access/edit/:id`;
export const system_accessview = `${mainPath}/system_access/detail`;

//users
export const users = `${mainPath}/user/listing`;
export const user_view = `${mainPath}/user/detail/:id`;
export const user_add = `${mainPath}/user/add`;
export const user_edit = `${mainPath}/user/edit`;
export const useredit = `${mainPath}/user/edit/:id`;
export const userview = `${mainPath}/user/detail`;

//jfx
export const jfxusers = `${mainPath}/jfx-user/listing`;
export const jfxuser_view = `${mainPath}/jfx-user/detail/:id`;
export const jfxuserview = `${mainPath}/jfx-user/detail`;

//kliring
export const kliringusers = `${mainPath}/kliring-user/listing`;
export const kliringuser_view = `${mainPath}/kliring-user/detail/:id`;
export const kliringuserview = `${mainPath}/kliring-user/detail`;

//payments
export const payment = `${mainPath}/payment/listing`;
export const payment_view = `${mainPath}/payment/detail/:id`;
export const payment_add = `${mainPath}/payment/add`;
export const payment_edit = `${mainPath}/payment/edit`;
export const paymentedit = `${mainPath}/payment/edit/:id`;
export const paymentview = `${mainPath}/payment/detail`;
//promotions
export const promotion = `${mainPath}/promotion/listing`;
export const promotion_view = `${mainPath}/promotion/detail/:id`;
export const promotion_add = `${mainPath}/promotion/add`;
export const promotion_edit = `${mainPath}/promotion/edit`;
export const promotionedit = `${mainPath}/promotion/edit/:id`;
export const promotionview = `${mainPath}/promotion/detail`;
//trades
export const tradeuser = `${mainPath}/tradeUser/listing/:id/:fromUserId`;

export const trade = `${mainPath}/trade/listing`;
export const schedule_orders = `${mainPath}/trade/schedule_order/:id`;
export const schedule_order = `${mainPath}/trade/schedule_order`;

export const trade_view = `${mainPath}/trade/detail/:id`;
export const trade_add = `${mainPath}/trade/add`;
export const trade_edit = `${mainPath}/trade/edit`;
export const tradeedit = `${mainPath}/trade/edit/:id`;
export const tradeview = `${mainPath}/trade/detail`;
//affiliates
export const affiliates = `${mainPath}/affiliates_referrals/listing`;
export const affiliates_view = `${mainPath}/affiliates_referrals/detail/:id`;
export const affiliatesview = `${mainPath}/affiliates_referrals/detail`;
//configuration
export const configuration = `${mainPath}/configuration/listing`;
export const configuration_add = `${mainPath}/configuration/add`;
export const configurationedit = `${mainPath}/configuration/edit`;
export const configuration_edit = `${mainPath}/configuration/edit/:id`;
//revenue
export const revenue = `${mainPath}/revenue/listing`;
export const revenue_view = `${mainPath}/revenue/detail/:id`;
export const revenueview = `${mainPath}/revenue/detail`;

//blogs
export const blogs = `${mainPath}/blogs/listing`;
export const blogs_view = `${mainPath}/blogs/detail/:id`;
export const blogs_add = `${mainPath}/blogs/add`;
export const blogs_edit = `${mainPath}/blogs/edit`;
export const blogsedit = `${mainPath}/blogs/edit/:id`;
export const blogsview = `${mainPath}/blogs/detail`;

//jobs
export const jobs = `${mainPath}/jobs/listing`;
export const jobs_view = `${mainPath}/jobs/detail/:id`;
export const jobs_add = `${mainPath}/jobs/add`;
export const jobs_edit = `${mainPath}/jobs/edit`;
export const jobsedit = `${mainPath}/jobs/edit/:id`;
export const jobsview = `${mainPath}/jobs/detail`;

//testimony
export const testimony = `${mainPath}/testimony/listing`;
export const testimony_view = `${mainPath}/testimony/detail/:id`;
export const testimony_add = `${mainPath}/testimony/add`;
export const testimony_edit = `${mainPath}/testimony/edit`;
export const testimonyedit = `${mainPath}/testimony/edit/:id`;
export const testimonyview = `${mainPath}/testimony/detail`;

//feature
export const feature = `${mainPath}/feature/listing`;
export const feature_view = `${mainPath}/feature/detail/:id`;
export const feature_add = `${mainPath}/feature/add`;
export const feature_edit = `${mainPath}/feature/edit`;
export const featureedit = `${mainPath}/feature/edit/:id`;
export const featureview = `${mainPath}/feature/detail`;

//basicSetting
export const basicSetting = `${mainPath}/basicSetting`;

//report
export const report = `${mainPath}/report/listing`;
export const report_view = `${mainPath}/report/detail/:id`;
export const reportview = `${mainPath}/report/detail`;
//backoffice
export const backoffice = `${mainPath}/backoffice/listing`;
export const backoffice_view = `${mainPath}/backoffice/detail/:id`;
export const backofficeview = `${mainPath}/backoffice/detail`;

//manageLogs
export const manage_logs = `${mainPath}/manage_logs/listing`;
export const manage_logs_view = `${mainPath}/manage_logs/detail/:id`;
export const manage_logsview = `${mainPath}/manage_logs/detail`;

//manageStocks
export const manage_stock = `${mainPath}/manage_stock/listing`;
// export const manage_stock_view = `${mainPath}/manage_stock /detail/:id`;
// export const manage_stockview = `${mainPath}/manage_stock /detail`;
//bank_request
export const bank_request = `${mainPath}/bank_request/listing`;
