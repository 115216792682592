import { Link } from "react-router-dom";
import Datetime from "react-datetime";
import moment from "moment";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export default ({
  searchValue,
  handleSearchChange,
  clearSerchFilter,
  options,
  SearchText,
  isSearchDate,
  field,
  setfields,
  isExportButton,
  isAddButton,
  isSelectBox,
  isInput,
  addLink,
  filter,
  setFilter,
  isAddModalButton,
  openModalAdd,
  isSelectOtherBox,
  selectSearchOptionOther,
  dropdownField,
  key,
  selectSearchOptionStatus,
  isSelectStatusBox,
  statusKey,
  csvData,
  isFutureHide,
  csvDataDownload_,
}) => {
  const handleChangePicker = async (selectedDate, name) => {
    // Perform actions based on the selected date and field name
    let dateFormat = selectedDate ? selectedDate.format("DD/MM/YYYY") : "";
    setfields((prev) => ({
      ...prev,
      [name]: dateFormat,
    }));
    // Rest of your code...
  };

  const handleChangeSelect = async (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      page: 1,
      [dropdownField]: value,
    });
  };

  const handleChangeSelectStatus = async (e) => {
    const { name, value } = e.target;
    setFilter({
      ...filter,
      [statusKey]: value,
    });
  };

  const exportToExcel = () => {
    // Create a workbook
    const workbook = XLSX.utils.book_new();

    // Convert JSON data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(csvData);

    // Append the worksheet to the workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Write the workbook and convert it to a Blob
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    const dataBlob = new Blob([excelBuffer], {
      type: "application/octet-stream",
    });

    // Save the file
    saveAs(dataBlob, "data.xlsx");
  };

  return (
    <div className="fillte_main flex-wrap d-flex justify-content-between align-items-end  ">
      <div className="filter_name px-2">Filter By:</div>

      <div className="center_fillter d-flex flex-wrap gap-3 align-items-end">
        <div className="selector_fillter d-flex gap-3 align-items-center">
          {isSelectBox && (
            <div className="filter_drop_filter">
              <select
                className="form-select filler_search"
                name={dropdownField}
                onChange={(e) => handleChangeSelect(e)}
              >
                <option value="" selected>
                  Select{" "}
                </option>
                <option value="">All </option>
                {options?.length > 0 &&
                  options.map((op, i) => {
                    return (
                      <option
                        value={op.value}
                        key={i}
                        selected={filter[dropdownField] === op.value}
                      >
                        {op.name}{" "}
                      </option>
                    );
                  })}
              </select>
            </div>
          )}
          {isSelectStatusBox && (
            <div className="filter_drop_filter">
              <select
                className="form-select filler_search"
                name={statusKey}
                onChange={(e) => handleChangeSelectStatus(e)}
              >
                <option value="" selected>
                  Select Status
                </option>
                {selectSearchOptionStatus?.length > 0 &&
                  selectSearchOptionStatus.map((op, i) => {
                    return (
                      <option
                        value={op.value}
                        key={i}
                        selected={filter[statusKey] === op.value}
                      >
                        {op.name}{" "}
                      </option>
                    );
                  })}
              </select>
            </div>
          )}
          {isSelectOtherBox && (
            <div className="filter_drop_filter">
              <select
                className="form-select filler_search"
                name={dropdownField}
                onChange={(e) => handleChangeSelect(e)}
              >
                <option value="" selected disabled>
                  Select{" "}
                </option>
                <option value="">All </option>
                {selectSearchOptionOther?.length > 0 &&
                  selectSearchOptionOther.map((op, i) => {
                    return (
                      <option
                        value={op.value}
                        key={i}
                        selected={filter[dropdownField] === op.value}
                      >
                        {op.name}{" "}
                      </option>
                    );
                  })}
              </select>
            </div>
          )}
        </div>

        <div className="date_time_slector">
          {isSearchDate ? (
            <div class="form-group m-0 d-flex gap-10">
              <div class="input-group w-auto d-flex flex-column">
                <h6>From Date</h6>
                <Datetime
                  key={key}
                  value={field.search_date || null}
                  inputProps={{
                    placeholder: "YYYY-MM-DD",
                    readOnly: true,
                    className: "form-control class-time-date-time",
                  }}
                  name="search_date"
                  dateFormat="YYYY-MM-DD"
                  timeFormat={false}
                  isValidDate={(current) => {
                    return current.isSameOrBefore(moment(), "day");
                  }}
                  onChange={(selectedDate) =>
                    handleChangePicker(selectedDate, "startDate")
                  }
                  closeOnSelect={true}
                />
                {/* &nbsp;{" "}
                <button
                  type="button"
                  className="btn btn-gradient-primary btn-sm"
                  onClick={clearSerchFilter}
                >
                  <i class="mdi mdi-close-circle"></i>
                </button> */}
              </div>
              <div class="input-group  w-auto d-flex flex-column">
                <h6>To Date</h6>

                <div className=" d-flex  ">
                  <Datetime
                    key={key}
                    value={field.search_date || null}
                    inputProps={{
                      placeholder: "YYYY-MM-DD",
                      readOnly: true,
                      className: "form-control class-time-date-time",
                    }}
                    name="search_date"
                    dateFormat="YYYY-MM-DD"
                    timeFormat={false}
                    isValidDate={(current) => {
                      return current.isAfter(
                        moment(field.startDate, "DD-MM-YYYY"),
                        "day"
                      );
                    }}
                    onChange={(selectedDate) =>
                      handleChangePicker(selectedDate, "endDate")
                    }
                    closeOnSelect={true}
                  />
                  <button
                    type="button"
                    className="btn btn-gradient-primary btn-sm"
                    onClick={clearSerchFilter}
                  >
                    <i class="mdi mdi-close-circle"></i>
                  </button>
                </div>
                {/* &nbsp;{" "} */}
              </div>
            </div>
          ) : (
            <div class="form-group m-0">
              <div class="input-group">
                <input
                  className="form-control"
                  autoComplete="chrome-off"
                  name="searchUnique"
                  type="text"
                  placeholder={SearchText}
                  value={filter?.search}
                  onChange={handleSearchChange}
                />
                &nbsp;{" "}
                <button
                  type="button"
                  className="btn btn-gradient-primary btn-sm"
                  onClick={clearSerchFilter}
                >
                  <i class="mdi mdi-close-circle"></i>
                </button>
              </div>
            </div>
          )}
        </div>
        {isExportButton && (
          <div className="button_cta">
            {/* <CSVLink
              data={csvData}
             
              onClick={csvDataDownload_}
              
            >
              Export
            </CSVLink> */}
            <button
              onClick={exportToExcel}
              className="btn btn-gradient-primary btn-lg"
            >
              Download XLSX
            </button>
            {/* <button type="button" className="btn btn-gradient-primary btn-lg">Export</button> */}
          </div>
        )}
        {isAddButton && (
          <div className="button_cta">
            <Link to={addLink} title="Add">
              <button
                type="button"
                className="d-flex align-items-center btn btn-lg btn-gradient-primary"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M11 13H6q-.425 0-.712-.288T5 12q0-.425.288-.712T6 11h5V6q0-.425.288-.712T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.712-.288T11 18z"
                    />
                  </svg>
                </span>
                Add
              </button>
            </Link>
          </div>
        )}
        {isAddModalButton && (
          <div className="button_cta">
            <Link to="#" title="Add" onClick={openModalAdd}>
              <button
                type="button"
                className="d-flex align-items-center btn btn-lg btn-gradient-primary"
              >
                <span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="13"
                    height="13"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M11 13H6q-.425 0-.712-.288T5 12q0-.425.288-.712T6 11h5V6q0-.425.288-.712T12 5q.425 0 .713.288T13 6v5h5q.425 0 .713.288T19 12q0 .425-.288.713T18 13h-5v5q0 .425-.288.713T12 19q-.425 0-.712-.288T11 18z"
                    />
                  </svg>
                </span>
                Add
              </button>
            </Link>
          </div>
        )}
      </div>

      {/* <div className="col-md-2">
            <Link to={Path.user_add} title="Add"><button type="button" class="btn btn-lg btn-gradient-primary"> + Add</button></Link>
            </div> */}
    </div>
  );
};
